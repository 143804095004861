import React, { useState } from "react";

import {
    makeStyles, Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSfdc } from "../lib/useSfdc";
import logo from '../imgs/360Connexglobal.png';
import { EnvironmentContextService } from "../services/environment-context.service";
import { SecurityService } from "../services/api/security.service";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '60%',
    },
    errorLabel: {
        color: "red",
        fontSize: "12px"
    }
}));

const LoginComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const { loginStatus } = useSfdc();
    const classes = useStyles();
    // TODO: Use DI
    const environmentContext: EnvironmentContextService = new EnvironmentContextService();
    const securityService: SecurityService = new SecurityService(environmentContext);

    const validateFields = (): boolean => {
        setEmailError('');
        setPasswordError('');

        // Check if the user has entered both fields correctly
        if (email === '') {
            setEmailError('Please enter your email')
            return false;
        }

        if (password === '') {
            setPasswordError('Please enter your password')
            return false;
        }

        return true;
    }

    const onLoginClick = async () => {
        try {
            if (!validateFields()) return;

            const response = await securityService.authenticate({
                username: email,
                password: password
            });
            // TODO: Remove this one we complete 2FA screen
            const resToken = await securityService.signIn({
                LookupToken: response.LookupToken,
                Token: '000000'
            });
    
            environmentContext.setAccessToken({ token: resToken.Token });
            environmentContext.setUserId(resToken.UserId)
            loginStatus(true);
            window.location.replace('/');            
        } catch (error) {
            setEmailError('Please enter a valid Email and Password!!')
            setPasswordError('Please enter a valid Email and Password!!')
        }

    }

    return (
        <div className="justify-content-center row">
            <div className="col-lg-5">
                <div className="card shadow-sm">
                    <div className="card-header pt-3 pb-3 text-center" id="header_outer">
                        <span>
                            <img className={classes.logo} src={logo} />
                        </span>
                    </div>
                    <div className="card-body p-4">
                        <div className="form-group  mb-2">
                            <label className="font-weight-light">Username</label>
                            <input type="text" className="input-username form-control"
                                placeholder="Enter your username"
                                value={email}
                                onChange={(ev) => setEmail(ev.target.value)}></input>
                            <label className={classes.errorLabel}>{emailError}</label>
                        </div>
                        <div className="form-group mb-2">
                            <label className="font-weight-light" >Password</label>
                            <input type="password" className="input-password form-control" placeholder="Enter your password"
                                value={password}
                                onChange={(ev) => setPassword(ev.target.value)}></input>
                            <label className={classes.errorLabel}>{passwordError}</label>
                        </div>
                        <div className="form-group  mb-2">
                            <Link to='/forgot-password'>Forgot your password?</Link>
                        </div>
                        <div className="form-group text-center">
                            <Button variant="contained" color="primary" className="card-button" component={Link} to="/" onClick={onLoginClick}>Login</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginComponent;