export enum AppEvents {
    SET_NAME = "APP/SET_NAME",
    SET_AGE = "APP/SET_AGE",
    SET_GATTEX_ATTENDEES = "APP/SET_GATTEX_ATTENDEES",
    SET_PAYLOAD_STATUS = "APP/SET_PAYLOAD_STATUS",
    SET_FORM_DATA = "APP/SET_FORM_DATA",
    SET_MULTIPLE_ATTENDEES = "APP/SET_MULTIPLE_ATTENDEES",
    ADD_NEW_STATUS = "APP/ADD_NEW_STATUS",
    CLEAR_NEW_STATUS = "APP/CLEAR_NEW_STATUS",
    SET_TAGS = "APP/SET_TAGS",
    FETCH_KOLS_DD = "APP/FETCH_KOLS_DD",
    FETCH_GATTEX_DD = "APP/FETCH_GATTEX_DD",
    SET_LOADING_STATUS_NE = "APP/SET_LOADING_STATUS_NE",
    SET_YEARS_LIST = "APP/SET_YEARS_LIST",
    SET_BRAND_FILTERS = "APP/SET_BRAND_FILTERS",
    SET_BRAND_FILTERS_NEW = "APP/SET_BRAND_FILTERS_NEW",
    SET_BRAND_FOCUS = "APP/SET_BRAND_FOCUS",
    SET_BRAND_FOCUS_ALL = "SET_BRAND_FOCUS_ALL",
    SET_BRAND_COLORS = "APP/SET_BRAND_COLORS",
    SET_MYVIEW_FILTERS = "APP/SET_MYVIEW_FILTERS",
    SET_HEADER_VALUE = "APP/SET_HEADER_VALUE",
    SET_REMOVED_VALUE = "APP/SET_REMOVED_VALUE",
    SET_CONGRESS_VALUE = "APP/SET_CONGRESS_VALUE",
    SET_YEAR_VALUE = "APP/SET_YEAR_VALUE",
    SET_TYPE_VALUE = "APP/SET_TYPE_VALUE",
    SET_CONGRESS_FILTER = "APP/SET_CONGRESS_FILTER",
    SET_INSITUTION_VALUE = "APP/SET_INSITUTION_VALUE",
    SET_FORM_SELECT = "APP/SET_FORM_SELECT",
    SET_BRAND_LIST = "APP/SET_BRAND_LIST",
    SET_URL_DATA = "APP/SET_URL_DATA",
    SET_REFRESH_STATUS ="APP/SET_REFRESH_STATUS",
    SET_SET_PRINT ="APP/SET_SET_PRINT",
    SET_MODAL = "APP/SET_MODAL"
}