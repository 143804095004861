export const Favorites: string[] = [
    "My Favorites",
]

//  *************       DARZALEX    ************** 
export const DARZALEX_Industry_Clinical : string[] = [
  "DARZALEX (daratumumab)",
  "ABECMA (ide-cel)",
  "Antibody-drug conjugates (ADCs)",
  "BCMA x CD3",
  "Bispecific antibodies",
  "Blenrep (belantamab mafodotin)",
  "CARTITUDE-1",
  "CARVYKTI (cilta-cel)",
  "CC-92480",
  "CEPHEUS",
  "Empliciti (elotuzumab)",
  "GMMG HD7",
  "GPRC5D x CD3",
  "GRIFFIN",
  "Iberdomide",
  "Imbruvica (ibrutinib)",
  "Immunomodulatory agents",
  "IMROZ",
  "Kyprolis (carfilzomib)",
  "MAIA",
  "Ninlaro (Ixazomib)",
  "PERSEUS",
  "Pomalyst (pomalidomide)",
  "Proteasome inhibitors",
  "Revlimid (lenalidomide)",
  "Sarclisa (isatuximab)",
  "Talquetamab",
  "Teclistamab",
  "Thalomid (thalidomide)",
  "Velcade (bortezomib)",
  "Venclexta (venetoclax)",
  "Xpovio (Selinexor)",
]
export const DARZALEX_Speaking : string[] = [
    "ABECMA (ide-cel)",
  "AL Amyloidosis",
  "Bispecific antibodies",
  "Blenrep (belantamab mafodotin)",
  "CAR T cell therapy",
  "CARVYKTI (cilta)",
  "CC-92480",
  "CD38 antibodies",
  "Connect MM Registry",
  "Darzalex",
  "High-risk MM",
  "iberdomide",
  "Immunotherapy",
  "Maintenance therapy",
  "MGUS",
  "MM risk stratification",
  "MRD negativity",
  "Multiple myeloma (MM)",
  "Newly diagnosed MM",
  "Novel therapeutics ",
  "Optimizing and individualizing myeloma treatments",
  "Patient-reported outcomes (PROs)",
  "Prognostic factors",
  "Quadruplet therapy",
  "Quality of life",
  "Real-world evidence",
  "Sarclisa (isatuximab)",
  "Smoldering multiple myeloma (SMM)",
  "Talquetamab",
  "Venclexta (venetoclax)",
  "Waldenström Macroglobulinemia (WM)",
  "Xpovio (Selinexor)",
]
export const DARZALEX_treatments : string[] = [
    "Patient-reported outcomes (PROs)",
    "Prognostic factors",
    "Quadruplet therapy",
    "Quality of life",
    "Real-world evidence",
    "Sarclisa (isatuximab)",
    "Smoldering multiple myeloma (SMM)",
    "Talquetamab",
    "Venclexta (venetoclax)",
    "Waldenström Macroglobulinemia (WM)",
    "Xpovio (Selinexor)",
]
export const DARZALEX_KOLIn_Clinical : string[] = [
   "AL Amyloidosis",
  "Antibody-drug conjugates (ADCs)",
  "Autologous stem cell transplant (ASCT)",
  "Bispecific antibodies",
  "CAR T cell therapy",
  "CD38 antibodies",
  "Cell-based therapies",
  "Clinical drug trials or experimental therapeutics",
  "Clinical endpoint surrogates for overall survival",
  "Early phase clinical trials",
  "Frontline therapy",
  "Gammopathies",
  "Health economics",
  "Healthcare disparities",
  "Lymphoma",
  "Maintenance therapy",
  "MGUS",
  "Minimal residual disease assessment",
  "MM risk stratification",
  "Monoclonal antibodies",
  "MRD negativity",
  "Multiple myeloma",
  "Novel therapeutics",
  "Outcomes research",
  "Patient-reported outcomes (PROs)",
  "Plasma cell dyscrasias",
  "Prognostic factors",
  "Quadruplet therapy",
  "Quality of life (QoL)",
  "Real-world evidence",
  "Smoldering multiple myeloma (SMM)",
  "Transplantation",
  "Tumor microenvironment",
  "Value, cost effectiveness",
  "Waldenstrom Macroglobulinemia (WM)",
]
export const DARZALEX_KOLIn_Clinical_Waldenstrom : string[] = [
]
export const DARZALEX_KOLIn_Engagement_Speaking_Preferences : string[] = [
    "Virtual",
    "Live",
    "Travel",
    "Local only",
]
export const DARZALEX_KOLIn_Engagement_Speaking : string[] = [
  "Preference - Virtual",
  "Preference - Live",
  "Preference - Travel",
  "Preference - Local only",
  "DSA",
  "Speakers Bureau",
  "Congress Presentation",
  "CME",
  "Social Media",
  "Videos",
  "Internal Speaking Events",
]
export const DARZALEX_KOLIn_Engagement_Content : string[] = [
    "Promotional",
  "Unbranded/DSA",
  "Case studies",
  "Video",
  "Podcasts",
  "Publications",
]
export const DARZALEX_KOLIn_Engagement_Consulting : string[] = [
    "Advisory Boards - Moderator",
  "Ad Boards - Participant",
  "Steering committees",
  "Guidelines",
  "Ad Hoc",
]
export const DARZALEX_KOLIn_Engagement_Research : string[] = [
   "Investigator Initiated",
  "Clinical trials",
  "Scientific",
]
export const DARZALEX_KOLIn_Engagement_Badges : string[] = [
  "Advocacy Groups",
  "APCC or Oncology Pathways",
  "Digital Activist",
  "Emerging KOL",
  "Healthcare Innovation",
  "Key Business Leader (KBL)",
  "Multicultural Initiatives",
  "Oncology Portfolio",
  "Patient Care Team",
  "Public Health Initiatives",
  "Real Work Value & Evidence",
  "Scientific Focus",
]
export const DARZALEX_KOLIn_Engagement_MM : string[] = [
    "Proactive Advocate",
  "Neutral",
  "Passive Supporter",
  "Detractor",
  "Unlisted",
]
export const DARZALEX_KOLIn_Engagement_HCP : string[] = [
    "Darzalex Believer",
  "Darzalex Dabbler",
  "VRd Defaulter",
  "VRd Loyalist",
  "MM Straggler",
]
export const DARZALEX_KOLIn_Engagement_Social : string[] = [
    "Twitter",
  "LinkedIn",
  "Instagram",
  "Photo file name",
]


//  *************      ERLEADA    ************** 
export const ERLEADA_Industry_Clinical : string[] = [
    "ERLEADA® (apalutamide)",
    "Abiraterone (generic)",
    "Androgen deprivation therapy (ADT)",
    "Androgen receptor antagonists",
    "Bispecifics",
    "Car-T",
    "Generic radiotheranotistic",
    "IO",
    "Localized Prostate Cancer",
    "Metastatic castration-sensitive prostate cancer (mCSPC)",
    "Non-metastatic castration-resistant prostate cancer (nmCSPC)",
    "Nubeqa (darolutamide)",
    "PARPs (including competitive specifics for prostate cancer)",
    "Prostate cancer",
    "Radiotherapy",
    "Xtandi (enzalutamide)",
    "Zytiga (abiraterone)",
]
export const ERLEADA_Speaking : string[] = [
    "ERLEADA® (apalutamide)",
    "Androgen deprivation therapy (ADT)",
    "Androgen receptor antagonists",
    "Bispecifics",
    "Car-T",
    "Disease state",
    "Generalized radiation oncology",
    "Generic radiotheranotistic",
    "IO",
    "Localized Prostate Cancer",
    "Metastatic castration-resistant prostate cancer (mCRPC)",
    "Metastatic castration-sensitive prostate cancer (mCSPC)",
    "Non-metastatic castration-resistant prostate cancer (nmCSPC)",
    "Nubeqa (darolutamide)",
    "Oral adherence speakers bureau",
    "PARPs (including competitive specifics for prostate cancer)",
    "Patient groups/Advocacy groups",
    "Population health speakers bureau ",
    "Prostate cancer",
    "Prostate cancer screening",
    "Prostate specific antigen (PSA)",
    "Xtandi (enzalutamide)",
    "Zytiga (abiraterone)",
]
export const ERLEADA_KOLIn_Clinical : string[] = [
    "Androgen deprivation therapy (ADT)",
    "Androgen receptor antagonists",
    "Prostate cancer screening",
    "Prostate specific antigen (PSA)",
    "PSA as an indicator of progression",
    "Shared decision making",
    "Emerging MOAs",
    "Real-world evidence",
    "Quality of life (QoL)",
    "Health related quality of life (HRQOL)",
    "Patient reported outcomes (PRO)",
    "Health economics",
    "High-risk populations",
    "Prostate/health Screening clinics",
    "Prostate cancer",
    "Localized Prostate Cancer",
    "Metastatic castration-resistant prostate cancer (mCSPC)",
    "Metastatic castration-sensitive prostate cancer (mCSPC)",
    "Non-metastatic castration-resistant prostate cancer (nmCSPC)",
    "Long-term outcomes",
    "Health disparities",
    "genomics",
    "High-precision imaging (HPI)",
    "Radar6",
]


//  *************       IMBRUVICA    ************** 

export const IMBRUVICA_Industry_Clinical_IMBRUVICA : string[] = [
   "1102",
  "ALLIANCE",
  "CAPTIVATE",
  "E1912",
  "GLOW",
  "iMAGINE",
  "RESONATE",
  "RESONATE2",
  "SELENE",
  "SHINE",
  "SYMPATICO",
  "Other",
]
export const IMBRUVICA_Industry_Clinical_Acalabrutinib : string[] = [
  "AMPLIFY",
  "CLL16",
  "D822BC00001",
  "ECHO",
  "ELEVATE",
  "ELEVATE RR",
  "ELEVATE TN",
]
export const IMBRUVICA_Industry_Clinical_Zanubrutinib : string[] = [
    "ALPINE",
  "ASPEN",
  "BGB-3111-306",
  "ROSEWOOD",
  "SEQUOIA",
]
export const IMBRUVICA_Industry_Clinical_Venetoclax : string[] = [
    "CLL 13",
  "CLL 16",
  "CLL14",
  "Cristallo",
]
export const IMBRUVICA_Industry_Clinical_Pirtobrutinib : string[] = [
    "BRUIN CLL",
  "BRUIN MCL",
  "NCT03740529",
]
export const IMBRUVICA_Industry_Clinical_CART : string[] = [
    "ZUMA 5", 
    "ZUMA-2",
]
export const IMBRUVICA_Industry_Clinical_Umbralisib : string[] = ["Ultra V", "Unity CLL"];
export const IMBRUVICA_Speaking : string[] = [
    "Chronic Graft vs Host Disease (cGVHD)",
  "Chronic Lymphocytic Leukemia (CLL)",
  "Follicular Lymphoma (FL)",
  "Mantle Cell Lymphoma (MCL)",
  "Marginal Zone Lymphoma (MZL)",
  "Non-Hodgkin’s Lymphoma (NHL)",
  "Waldenström Macroglobulinemia (WM)",
]
export const IMBRUVICA_Speaking_Speaker : string[] = [
    "Yes",
    "No",
]
export const IMBRUVICA_Commercial_In : string[] = [
    "AKT inhibitors",
  "B-cell development/ ontogeny",
  "B-cell malignancy and immune dysfunction",
  "B-cell signaling",
  "BCL2 inhibitors",
  "BTK inhibitors",
  "CAR T cells",
  "Chronic Graft-vs-Host Disease (cGVHD)",
  "Chronic Lymphocytic Leukemia (CLL)",
  "Clinical endpoint surrogates for overall survival",
  "Comorbidities",
  "Follicular Lymphoma (FL)",
  "Health economics",
  "High risk Patient QOL",
  "High-risk cytogenetics in B-cell malignancies",
  "Mantle Cell Lymphoma (MCL)",
  "Marginal Zone Lymphoma (MZL)",
  "Molecular profiling of B-cell malignancies",
  "Non-Hodgkin’s lymphoma (NHL)",
  "Novel small molecule therapies",
  "PD1/PDL1 pathway",
  "PI3K inhibitors",
  "Quality of life",
  "Real-world evidence",
  "Unmutated IGHV",
  "Waldenstrom Macroglobulinemia (WM)",
]
export const IMBRUVICA_Medical_In : string[] = [
    "High",
    "Medium",
    "Low",
]
export const IMBRUVICA_Engagement_Badges : string[] = [
    "Innovation",
  "Emerging Leader",
  "Diversity",
  "Digital",
  "RWE",
  "Access",

]
export const IMBRUVICA_Engagement_Advocacy_MONO : string[] = [
    "Proactive Advocate",
  "Passive Supporter",
  "Neutral",
  "Detractor",
  "Unlisted",


]
export const IMBRUVICA_Engagement_Advocacy_IV : string[] = [
    "Proactive Advocate",
  "Passive Supporter",
  "Neutral",
  "Detractor",
  "Unlisted",
]
export const IMBRUVICA_Indication_Focus : string[] = [
    "CLL",
  "MCL",
  "WM",
  "MZL",
  "FL",
  "CGVHD",
  "Pediatric CGVHD/BMT",
]
export const IMBRUVICA_Treatment : string[] = [
    "IMBRUVICA",
  "IMBRUVICA Combo",
  "CIT",
  "AntiCD20",
  "Acalabrutinib",
  "Zanubrutinib",
  "Venetoclax",
  "Pirtobrutinib",
  "Umbralisib",
  "Ublituximab",
  "Combination Therapy",
  "CAR-T Therapy",

]

export const  EXP_CLINICAL_BRAND : String[] = [
    "ENERGIZE EFFICACY",
    "IMBRUVICA FIRST",
    "ENGAGE PATIENTS",
]

export const  EXP_CLINICAL_BRAND_MEDICAL : String[] = [
    "Reinforce long-term benefits with continuous ibrutinib [inc HR]",
    "Differentiate through Dosing and DDI",
    "Building BTKi Saftey Parity and Dose Management",
    "Fixed Duration Treatment and Evolving Landscapes",
]

export const  EXP_CLINICAL_BRAND_DARZALEX : String[] = [
    "Ignite Front-Line Momentum",
    "Build the Quad Foundation",
    "Maximize Duration to Optimize Outcomes",
]


export const  EXP_CLINICAL_BRAND_MEDICAL_DARZALEX : String[] = [
    "Ignite Front-Line Momentum",
    "Build the Quad Foundation",
    "Maximize Duration to Optimize Outcomes",
]

export const  EXP_CLINICAL_BRAND_CARVYKTI_DARZALEX : String[] = [
    "Activate Advocates",
    "Extend Advocates",
    "Build Preference as Supply Increases",
    "Build Leadership",
]

export const  EXP_CLINICAL_BRAND_MEDICAL_ERLEADA_MAGNIFY : String[] = [
    "RWE D&I",
    "support study recruitment",
    "Real world PSA responses",
    "improved screening and appropriate treatment",
]

export const  EXP_CLINICAL_BRAND_ERLEADA : String[] = [
    "Expand & activate",
    "Collaborate and educate",
    "Leverage channels",
    "educate on PSA",
]

export const  EXP_CLINICAL_BRAND_COM_MAGNIFY_ERLEADA : String[] = [
    "Identify and activate Community KOL’s",
    "educate HCP networks",
    "Pro-talk advisory counsel",
]
export const  EXP_CLINICAL_BRAND_COM_BROADEN_ERLEADA : String[] = [
    "cross-functional Urology speakers",
    "share best practices",
    "new champions",
]


export const  EXP_CLINICAL_BRAND_MEDICAL_ERLEADA_BROADEN : String[] = [
    "Generate data",
    "drive consensus",
    "creating the sense of urgency",
    "Reactive delivery of guideline",
]


export const  EXP_CLINICAL_BRAND_MEDICAL_ERLEADA : String[] = [
    "confidence through education",
    "perceived clinical barriers",
    "correlation of PSA to endpoints",
    "Amplify the PSA story",
    "generate PSA data and publications",
    "Engage with PERC",
    "key market trends",
    "RADAR guidelines",
]

let currentUrl=window.location.href;
let exceptionurlname=process.env.REACT_APP_URL_NAME? process.env.REACT_APP_URL_NAME :"null";
let BRAND1_NAME=process.env.REACT_APP_BRAND1_NAME? process.env.REACT_APP_BRAND1_NAME :"";
let BRAND2_NAME=process.env.REACT_APP_BRAND2_NAME? process.env.REACT_APP_BRAND2_NAME :"";
let BRAND3_NAME=process.env.REACT_APP_BRAND3_NAME? process.env.REACT_APP_BRAND3_NAME :"";

export const BRAND1:string= currentUrl.includes(exceptionurlname)?BRAND1_NAME : 'Leukemia-Lymphoma'


export const BRAND2:string= currentUrl.includes(exceptionurlname)?BRAND2_NAME : 'MM Portfolio'


export const BRAND3:string= currentUrl.includes(exceptionurlname)?BRAND3_NAME : 'Prostate Franchise'

export const BRANDdemo:string= 'Oncology'