import React, { useState, useEffect, useContext } from "react";
import { SfdcApi } from "./sfdcApi";
import getCookie from "../shared/utilities/get-cookie";
import setCookie from "../shared/utilities/set-cookie";
import { EnvironmentContextService } from "../services/environment-context.service";

export interface IKOL_Profile {
  Id?: string;
  Logo__c?: string;
  info__c?: string;
  Company_Color__c?: string;
  View__c?: string;
  Header_Background_Image__c?: string;
  Client_Name__c?: string;
}
interface SfdcHook {
  kolProfile?: IKOL_Profile;
  user?: any;
  api?: SfdcApi;
  connGlobal?: any;
  loginStatus?: any;
  permissionId?: number;
  setIsLoading?: (loading: boolean) => void;
  firstTimeLogin?: boolean;
  setFirstTimeLogin?: any;
  UserOrGroupId?: any;
  division?: any;
  FFS__c?: any;
  globalData?: any;
  globalFocus?: any;
  globalFocus2?: any;
  username?: any;
  newdivision?: any;
  companycheck?: any;
}

export const sfdcContext = React.createContext<SfdcHook>({});

export function ProvideSfdc({ children }) {
  const sfdc = useProvideSfdc();
  return <sfdcContext.Provider value={sfdc}>{children}</sfdcContext.Provider>;
}

export function useSfdc() {
  return useContext(sfdcContext);
}


function useProvideSfdc() {
  const [kolProfile, setKolProfile] = useState<IKOL_Profile>(
    {} as IKOL_Profile
  );
  const [user, setUser] = useState<any>(null);
  const [api, setApi] = useState<any>(null);
  const [connGlobal, setConnGlobal] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [permissionId, setPermissionId] = useState<number>(3);

  const [newdivision, setNewdivision] = useState<string>("");
  const [username, setUsername] = useState<string>("");

  const [division, setDivision] = React.useState<any>([]);
  const [globalData, setglobalData] = React.useState<any>([]);
  const [globalFocus, setglobalFocus] = React.useState<any>([]);
  const [globalFocus2, setglobalFocus2] = React.useState<any>([]);
  const [FFS__c, setFFS__c] = React.useState<any>("");

  const [companycheck, setCompanycheck] = useState<string>("");

  const [firstTimeLogin, setFirstTimeLogin] = useState<boolean>(false);
  const [UserOrGroupId, setUserOrGroupId] = useState<any>(null);

  const loginStatus = async (isLogIn: boolean) => {
    if (isLogIn) {
      setCookie("jsForceToken", "access_token");
    }
    else {
      setCookie("jsForceToken", "");
    }

    window.location.reload();
  };

  useEffect(() => {
    const applyConnection = async function () {


      // const conn = await getConn();
      var cookie = getCookie("jsForceToken");
      if (cookie == "") return;

      const api = new SfdcApi();
      setApi(api);
      //Set logged in user
      const environmentService: EnvironmentContextService = new EnvironmentContextService();
      const userId = environmentService.getUserId();
      const user = await api.getUser(userId);
      const user_id = user?.records[0].Id as string;
      setUserOrGroupId(user_id);
      setUser(user);
      const kolProfile: any = await api.getKOLPorfile();
      setKolProfile(kolProfile);

      Promise.all([
        // api?.getGlobal(user_id),
        // api?.getGlobalName(user_id),
        // api?.getGlobalDivision(user_id),
        // api?.getGlobalFocus(user_id),
        // api?.getGlobalFocus2(user_id),
        api?.getGlobal(user_id),
        api?.getGlobalName(user_id),
        api?.getGlobalDivision(user_id),
        api?.getGlobalFocus(user_id),
        api?.getGlobalFocus2(user_id),
        //api?.getMasterId("Leukemia-Lymphoma"),
        //api?.getFocusId("a8J19000000U4yrEAC"),
        //api?.getTopicId("a8L19000000A8Y4EAK"),
        //api?.testingapi(),
      ]).then(
        (data: any) => {
          let role = 0;

          if (data[0]?.records) {
            if (data[0]?.records?.[0]?.Read_Only__c == true) {
              role = 3;
            } else {
              role = 1;
              // Reacd
            }
            setPermissionId(role);
            setDivision(data[0]?.records?.[0]?.Division__c);
            setFFS__c((data[0]?.records?.[0].FFS__c == "FFS Admin") ? "" : ((data[0]?.records?.[0].FFS__c == null) ? "" : ((data[0]?.records?.[0].FFS__c == "No FFS") ? false : true)))
            setglobalData(data[0]?.records);
            setCompanycheck(data[0]?.records?.[0]?.Company__c);
          }
          if (data[1]) {
            setUsername(data[1]);
          }

          if (data[2]?.records) {
            setNewdivision(data[2]?.records?.[0].Division__c);
          }


          if (data[3]?.records) {
            setglobalFocus(data[3]?.records);
          }

          if (data[4]?.records) {
            setglobalFocus2(data[4]?.records);
          }


        },
        (error) => { }
      );
    };

    applyConnection();

    return () => {
    };
  }, []);

  // Return the user object and auth methods
  return {
    kolProfile,
    user,
    api,
    loginStatus,
    setIsLoading,
    isLoading,
    permissionId,
    firstTimeLogin,
    setFirstTimeLogin,
    UserOrGroupId,
    division,
    FFS__c,
    newdivision,
    username,
    globalData,
    globalFocus,
    globalFocus2,
    companycheck,
    connGlobal
  };
}
