import { AppEvents } from './events';
import { IAction } from './actions';
import {BRAND1,BRAND2,BRAND3} from './shared/utilities/filter-const'
console.log("Brandzzz",BRAND1,BRAND2,BRAND3)

const initState: IState = {
    name: "",
    age: 123456,
    gattexAttendees: "",
    submitPayload: "",
    formData: {},
    multipleAttendees: "",
    updatedKolStatus: [],
    tags: [],
    kolDropDowns: [],
    gattexDropDowns: [],
    loadingStatus: 0,
    years: [],
    brandFilters: [],
    brandFiltersNew:[],
    brandFocus:[],
    brandFocusAll:[],
    BrandColors:[],
    myView: false,
    headerValue: {},
    removedItems:[],
    congressValue: {},
    yearValue: {},
    typeValue: {},
    congressFilter: {},
    insitutionValue: {},
    formSelect: '',
    brandList: [],
    urlData:{},
    refreshStatus:false,
    modal:false,
    setPrint:false
};

export interface IState {
    name: string;
    age: number;
    gattexAttendees: string;
    submitPayload: string;
    formData: object;
    multipleAttendees: any;
    updatedKolStatus: any;
    tags: any;
    kolDropDowns: any;
    gattexDropDowns: any;
    loadingStatus: any;
    years: any;
    brandFilters: any;
    brandFiltersNew:any;
    brandFocus:any;
    brandFocusAll:any;
    BrandColors:any;
    headerValue: any;
    removedItems:any;
    congressValue: any;
    yearValue: any;
    typeValue: any;
    congressFilter: any;
    insitutionValue: any;
    formSelect: any;
    brandList: any;
    modal:boolean;
    urlData:object;
    refreshStatus:boolean;
    myView:boolean;
    setPrint:boolean;
}

export const reducer = (state: IState = initState, action: IAction): IState => {
    switch (action.type) {
        case AppEvents.SET_NAME:
            return { ...state, name: action.payload };

        case AppEvents.SET_AGE:
            return { ...state, age: action.payload }

        case AppEvents.SET_GATTEX_ATTENDEES:
            return { ...state, gattexAttendees: action.payload }

        case AppEvents.SET_PAYLOAD_STATUS:
            return { ...state, submitPayload: action.payload }

        case AppEvents.SET_FORM_DATA:
            return { ...state, formData: action.payload }

        case AppEvents.SET_MULTIPLE_ATTENDEES:
            return { ...state, multipleAttendees: action.payload }
        case AppEvents.ADD_NEW_STATUS:
            // let idAlreadyExists = state.updatedKolStatus.indexOf(action.payload.id) > -1;
            let idAlreadyExists;
            if (state.updatedKolStatus.some(person => person.id === action.payload.id)) {
                idAlreadyExists = true;
            } else {
                idAlreadyExists = false;
            }

            let chosenIds = state.updatedKolStatus; //sent to the fe- returned instead of ...state, and payload

            if (idAlreadyExists) {
                // chosenIds = chosenIds.filter(id => id != action.payload.id); 
                chosenIds = chosenIds.filter(function (obj) {
                    return obj.id !== action.payload.id;
                });
                chosenIds.push(action.payload);
            }
            else {
                // modify the COPY, not the original
                chosenIds.push(action.payload);
            }
            return {
                // ...state, 
                // updatedKolStatus: [...state.updatedKolStatus, action.payload]
                ...state,
                updatedKolStatus: chosenIds
            }
        case AppEvents.CLEAR_NEW_STATUS:
            return {
                ...state, updatedKolStatus: action.payload
            }
        case AppEvents.SET_TAGS:
            return {
                ...state, tags: action.payload
            }
        case AppEvents.FETCH_KOLS_DD:
            return {
                ...state, kolDropDowns: action.payload
            }
        case AppEvents.FETCH_GATTEX_DD:
            return {
                ...state, gattexDropDowns: action.payload
            }
        case AppEvents.SET_MODAL:
            return {
                ...state, modal: action.payload
            }
        case AppEvents.SET_LOADING_STATUS_NE:
            return {
                ...state, loadingStatus: action.payload
            }
        case AppEvents.SET_YEARS_LIST:
            return {
                ...state, years: action.payload
            }
        case AppEvents.SET_BRAND_FILTERS:
            return {
                ...state, brandFilters: action.payload
            }
        case AppEvents.SET_BRAND_FILTERS_NEW:
                return {
                    ...state, brandFiltersNew: action.payload
                }
        case AppEvents.SET_BRAND_FOCUS:
                    return {
                        ...state, brandFocus: action.payload
                    }
        case AppEvents.SET_BRAND_FOCUS_ALL:
                    return {
                        ...state, brandFocusAll: action.payload
                    }
        case AppEvents.SET_BRAND_COLORS:
            return {
                ...state, BrandColors: action.payload
            }

        case AppEvents.SET_MYVIEW_FILTERS:
            return {
                ...state, myView: action.payload
            }
        case AppEvents.SET_HEADER_VALUE:
            return {
                ...state, headerValue: action.payload
            }
        case AppEvents.SET_REMOVED_VALUE:
            return {
                ...state, removedItems: action.payload
            }
        case AppEvents.SET_CONGRESS_VALUE:
            return {
                ...state, congressValue: action.payload
            }
        case AppEvents.SET_YEAR_VALUE:
            return {
                ...state, yearValue: action.payload
            }
        case AppEvents.SET_YEAR_VALUE:
            return {
                ...state, typeValue: action.payload
            }
        case AppEvents.SET_CONGRESS_FILTER:
            return {
                ...state, congressFilter: action.payload
            }
        case AppEvents.SET_INSITUTION_VALUE:
            return {
                ...state, insitutionValue: action.payload
            }
        case AppEvents.SET_FORM_SELECT:
            return {
                ...state, formSelect: action.payload
            }
        case AppEvents.SET_BRAND_LIST:
            return {
                ...state, brandList: action.payload
            }
        case AppEvents.SET_URL_DATA:
            return {
                ...state, urlData: action.payload
            }
        case AppEvents.SET_REFRESH_STATUS:
            return {
                ...state, refreshStatus: action.payload
            }
            case AppEvents.SET_SET_PRINT:
            return {
                ...state, setPrint: action.payload
            }
        default:
            return state
    }
}