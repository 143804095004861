import {
  IAddProfile,
  INote,
  IFlatProfile,
} from "../components/interfaces/kol-profile.modal";
import {
  ITag,
  IEngagement,
  IMasterEngagement,
} from "../components/interfaces/engagment.modal";

import { BRAND1, BRAND2, BRAND3, BRANDdemo } from "../shared/utilities/filter-const";
import { EnvironmentContextService } from "../services/environment-context.service";
import axios from "axios";
import moment from "moment";

class SfdcApi {

  private KOL_Profile_Portal__pc: string;
  private RecordTypeId: string;
  private Account__c: string;
  private KOL_Profile_Id: string;
  private KOL_Portal__c: string;
  private KOL_Api_Url: string;
  private KOL_Api_Base_Url: string;
  private KOL_Portal_Name: string;
  private KOL_UserID: string;

  public constructor() {
    this.KOL_Profile_Portal__pc =
      process.env.REACT_APP_KOL_PROFILE_PORTAL_ID ?? ``;
    this.KOL_Profile_Id = process.env.REACT_APP_KOL_PROFILE_ID ?? ``;
    this.RecordTypeId = "012j0000000pbkFAAQ";
    //this.Account__c = "0018B00000AiBGF";
    this.Account__c = process.env.REACT_APP_ACCOUNT__c ?? ``;
    this.KOL_Portal__c = process.env.REACT_APP_KOL_PORTAL_ID ?? ``;
    this.KOL_Api_Base_Url = process.env.REACT_APP_KOL_API_URL ?? '';
    this.KOL_Api_Url = `${this.KOL_Api_Base_Url}/kol` ?? '';
    this.KOL_Portal_Name = process.env.REACT_APP_KOL_PORTAL_NAME ?? '';
    const environmentService: EnvironmentContextService = new EnvironmentContextService();
    const userId = environmentService.getUserId();
    this.KOL_UserID = userId ?? '';
  }

  public async getUser(userId: string) {

    try {
      //let userId = 'cempqeAkbBlt9emiSM';  //ageorge@customhouseinc.com.fullcopy USER  
      //let userId = '0053I000002dNKkQAM';  //ageorge@customhouseinc.com.fullcopy USER
      //let userId = '0053Z00000LOFbRQAX'; // jbitripleadmin@blulava.com.fullcopy
      const response = await axios.get(`${this.KOL_Api_Base_Url}/user/getUser/${this.KOL_UserID}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching User:", error);
      throw error;
    }
  }

  public async getKOLPorfile() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLPorfile/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching kol Profile Portal Message:", error);
      throw error;
    }
  }

  public async getAccounts() {
    const query = {
      "RecordType.Name": "Client",
    };
    const fields = [
      "Id",
      "LastModifiedDate",
      "Name",
      "Phone",
      "PersonMobilePhone",
      "BillingAddress",
      "PersonEmail",
      "PersonMailingAddress",
      "RecordType.Name",
      "IsPersonAccount",
      "Profile_Image_Large__c",
      "Profile_Image__c",
      "Profile_Thumbnail__c",
    ];
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getAccounts`);
      return response.data;
    } catch (error) {
      console.error("Error fetching Accounts:", error);
      throw error;
    }
  }

  public async getKols(sortOrder: "ASC" | "DESC" = "ASC") {

    let RecordTypeName = "Key Opinion Leader";
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKols/${RecordTypeName}/${sortOrder}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching get Kols:", error);
      throw error;
    }
  }

  public async fetchProfilePortalMessage() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchProfilePortalMessage/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching Profile Portal Message:", error);
      throw error;
    }
  }

  public async fetchHeader(UserorGroupId: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchHeader/${UserorGroupId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching Header:", error);
      throw error;
    }
  }

  public async fetchCongress() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCongress?profilePortal=${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching congress:", error);
      throw error;
    }
  }

  public async fetchAllInsitution(
    newdivision?: string,
    username?: string,
    FFS__cValue?: string
  ) {
    try {

      const response = await axios.get(`${this.KOL_Api_Url}/fetchAllInsitution?KOL_Portal_Name=${this.KOL_Portal_Name}&FFS__cValue=${FFS__cValue ?? ''}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchAllInsitution", error);
      throw error;
    }
  }

  public async fetchInsitutionDetail(id, FFS__cValue?: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchInsitutionDetail/${id}/${this.KOL_Portal_Name}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchCommercialEngagementsHome", error);
      throw error;
    }
  }

  public async fetchTotalEngagementsHome(formSelect?: any, brandFocus?: any[], FFS__cValue?: string): Promise<any> {
    try {
      const params = new URLSearchParams();

      if (formSelect !== undefined && formSelect !== "") {
        params.append('congressId', formSelect);
      }

      params.append('profilePortal', this.KOL_Profile_Portal__pc);

      (brandFocus ?? []).forEach(focus => params.append('brandFocus', focus.value));

      if (FFS__cValue !== undefined && FFS__cValue !== "") {
        params.append('ffs', FFS__cValue);
      }

      const response = await axios.get(`${this.KOL_Api_Url}/fetchTotalEngagementsHome`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error fetching total engagements home:", error);
      throw error;
    }
  };

  public async fetchCommercialEngagementsHome(
    profileId?: string,
    formSelect?: any,
    UserOrGroupId?: boolean,
    queryParams1?: string,
    FFS__cValue?: string
  ) {

    try {
      const params = new URLSearchParams();

      if (formSelect !== undefined && formSelect !== "") {
        params.append('congressId', formSelect);
      }

      const response = await axios.get(`${this.KOL_Api_Url}/fetchCommercialEngagementsHome/${this.KOL_Profile_Portal__pc}`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error fetchCommercialEngagementsHome", error);
      throw error;
    }
  }

  public async fetchMedicalEngagementsHome(congressId?: any, brandFocus?: string, ffSc?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchMedicalEngagementsHome?brandFocus=${brandFocus}&ffSc=${ffSc}&congressId=${congressId ?? ''}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching medical engagements:", error);
      throw error;
    }
  }

  public async getMostActive(
    UserOrGroupId?: boolean,
    queryParams?: string,
    queryBrand?: string,
    FFS__cValue?: string
  ) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getMostActive/${this.KOL_Profile_Portal__pc}/${BRAND1}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getMostActive2", error);
      throw error;
    }
  }

  public async getMostActive1(
    UserOrGroupId?: boolean,
    queryParams?: string,
    queryBrand?: string,
    FFS__cValue?: string
  ) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getMostActive/${this.KOL_Profile_Portal__pc}/${BRAND2}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getMostActive2", error);
      throw error;
    }
  }

  public async getMostActive2(
    UserOrGroupId?: boolean,
    queryParams?: string,
    queryBrand?: string,
    FFS__cValue?: string
  ) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getMostActive/${this.KOL_Profile_Portal__pc}/${BRAND3}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getMostActive2", error);
      throw error;
    }
  }

  public async getLeastActive() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getLeastActive`);
      return response.data;
    } catch (error) {
      console.error("Error fetching LeastActive:", error);
      throw error;
    }
  }

  //KOL OVERVIEW COMPONENT

  public async getKOLoverview() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLoverview`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLoverview:", error);
      throw error;
    }
  }

  public async getKOLoverviewNew(
    queryParams1: string[],
    newdivision: string,
    username: string
  ) {
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getKOLoverviewNew/${this.KOL_Profile_Portal__pc}`,
        queryParams1
      );
      return response.data;
    } catch (error) {
      console.error("Error getKOLoverviewNew", error);
      throw error;
    }
  }

  public async getKOLtarget() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLtarget`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLtarget", error);
      throw error;
    }
  }

  public async getKOLtargetNew(
    queryParams1: string,
    newdivision: string,
    username: string
  ) {
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getKOLtargetNew?KOL_Profile_Portal__pc=${this.KOL_Profile_Portal__pc}`,
        queryParams1
      );
      return response.data;
    } catch (error) {
      console.error("Error getKOLtargetNew", error);
      throw error;
    }
  }

  public async getLeadersByExpert(expertType: string) {
    // check docs for != for tier...
    const query = {
      KOL_Profile_Portal__pc: this.KOL_Profile_Portal__pc,
      Expert_Type__pc: `${expertType}`,
    };
    const fields = ["count(Name)"];
    // return this.conn.sobject("Account").find(query, fields);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getLeadersByExpert`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getLeadersByExpert:", error);
      throw error;
    }
  }

  public async getTotalAcedemic() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getTotalAcedemic`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getTotalAcedemic:", error);
      throw error;
    }    
  }

  public async getTotalAcedemicNew(
    queryParams1: string[],
    newdivision: string,
    username: string
  ) {
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getTotalAcedemicNew/${this.KOL_Profile_Portal__pc}`,
        queryParams1
      );
      return response.data;
    } catch (error) {
      console.error("Error TotalAcedemicNew", error);
      throw error;
    }
  }

  public async getInstitutionsTotalEngment() {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getInstitutionsTotalEngment`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getInstitutionsTotalEngment:", error);
      throw error;
    }    
  }


  public async getInstitutionsTotalEngmentNew(
    queryParams: string,
    congressFilter: string,
    year: string,
    UserOrGroupId: any,
    newdivision: string,
    username: string
  ) {


    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getInstitutionsTotalEngmentNew?KOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}&congress=${Object.keys(congressFilter).length !== 0 ? congressFilter : ''}&year=${year}`,
        queryParams
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching getInstitutionsTotalInstitutionsNew:", error);
      throw error;
    }
  }

  public async getInstitutionsTotalInstitutions() {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getInstitutionsTotalInstitutions`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getInstitutionsTotalInstitutions:", error);
      throw error;
    }
  }

  public async getInstitutionsTotalInstitutionsNew(
    queryParams: string[],
    newdivision: string,
    username: string
  ) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getInstitutionsTotalInstitutionsNew?brands=${queryParams}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getInstitutionsTotalInstitutionsNew:", error);
      throw error;
    }
  }

  public async getInstitutionsAffiliated() {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getInstitutionsAffiliated`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getInstitutionsAffiliated:", error);
      throw error;
    }
  }

  public async meetings1to1() {
    // check docs for != for tier...
    const query = {
      KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
      Engagement_Type__c: "1:1 Meeting",
    };
    const fields = [
      "Attendee_Type__c",
      "Engagement_Date__c",
      "Id",
      "KOLAccount__r.Name",
    ];

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/meetings1to1`);
      return response.data;
    } catch (error) {
      console.error("Error fetching meetings1to1:", error);
      throw error;
    }    
  }

  // ALL CHART QUERYS GO BELOW

  //PIE CHART Q

  public async getKolEngagementReach(
    queryParams: string,
    UserOrGroupId: any,
    queryParams1: string,
    FFS__cValue?: string
  ) {
    const body = {
      brandNames: queryParams,
      focusNames: queryParams1,
      KOL_Profile_Portal__c_Name: this.KOL_Portal_Name
    }

    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getKolEngagementReach?KOL_Portal_Name=${this.KOL_Portal_Name}`,
        body
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching getKolEngagementReach:", error);
      throw error;
    }

  }

  public async getKOLbarChat() {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLbarChat`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLbarChat:", error);
      throw error;
    }
  }

  public async getByEngagementType(type: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getByEngagementType`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getByEngagementType:", error);
      throw error;
    }    
  }

  public async getByEngagement(
    brandsFilters: string[],
    UserOrGroupId?: boolean,
    brandsFocus?: string[],
    FFS__cValue?: boolean
  ) {
    if (brandsFilters.length !== 0) {
      try {
        const response = await axios.get(`${this.KOL_Api_Url}/getByEngagement?KOL_Portal_Name=${this.KOL_Portal_Name}&brandsFilters=${brandsFilters}&brandsFocus=${brandsFocus}&ffsc=${FFS__cValue}`);
        return response.data;
      } catch (error) {
        console.error("Error fetching global focus:", error);
        throw error;
      }
    }
  }


  public async getDialogEngagementTrack(type: string, tier: number) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getDialogEngagementTrack`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getDialogEngagementTrack:", error);
      throw error;
    }
  }

  public async get1to1MeetingsPrevoius() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/get1to1MeetingsPrevoius`);
      return response.data;
    } catch (error) {
      console.error("Error fetching get1to1MeetingsPrevoius:", error);
      throw error;
    }
  }

  public async get1to1MeetingsUpcoming(year: string,
    brandNames?: string[],
    brandFocus?: any[],
    FFS__cValue?: any) {

    const params = new URLSearchParams();

    params.append('year', year);
    params.append('portalName', this.KOL_Portal_Name);
    params.append('createdById', this.KOL_UserID)  
    if (FFS__cValue !== undefined && FFS__cValue !== "") {
      params.append('ffs', FFS__cValue);
    }

    (brandNames ?? []).forEach(name => params.append('brandNames', name));
    (brandFocus ?? []).forEach(focus => params.append('brandFocus', focus.value));

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/get1to1MeetingsUpcoming`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async get1to1Meetings(attendeeType: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/get1to1Meetings?attendeeType=${attendeeType}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching get1to1Meetings3:", error);
      throw error;
    }
  }

  public async getEvents(
    year: string,
    UserOrGroupId?: boolean,
    queryParams?: string,
    queryParams1?: string
  ) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getEvents`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getEvents:", error);
      throw error;
    }    
  }

  public async getPreviousEvents() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getPreviousEvents`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getPreviousEvents:", error);
      throw error;
    }    
  }

  //Engagement Topic Leaderboard Tile

  public async fetchCountBrandFocus(
    isCurrent: boolean,
    UserOrGroupId?: boolean,
    FFS__cValue?: string
  ) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCountBrandFocus?KOL_Portal_Name=${this.KOL_Portal_Name}&FFS__cValue=${FFS__cValue ?? ''}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching CountBrandFocus:", error);
      throw error;
    }
  }

  public async fetchCountDiseaseFocus(
    isCurrent: boolean,
    UserOrGroupId?: boolean,
    FFS__cValue?: string
  ) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCountDiseaseFocus`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchCountDiseaseFocus:", error);
      throw error;
    } 
  }

  public async fetchAlignment() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchAlignment?profilePortal=${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching permissions:", error);
      throw error;
    }
  }

  public async congressRelatedEvents() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/congressRelatedEvents/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching congress related events:", error);
      throw error;
    }
  }

  public async fetchKolAttendees(queryParams1: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKolAttendees?KOL_Profile_Portal_c=${this.KOL_Profile_Portal__pc}&brands=${queryParams1}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }


  public async fetchLeaderAttendee() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchLeaderAttendee/${this.KOL_Profile_Portal__pc}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchLeaderAttendee", error);
      throw error;
    }
  }

  public async fetchClientrAttendee() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchClientrAttendee/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchCommercialEngagementsHome", error);
      throw error;
    }
  }

  public async getSingleEngagement(id: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getSingleEngagement?id=${id}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error getSingleEngagement: ", error);
      throw error;
    }
  }

  public async fetchEngagementsAll(
    queryParams: string,
    congressFilter: string,
    UserOrGroupId: any,
    queryParams1: string,
    FFS__cValue?: string
  ) {
    const body = {
      brandNames: queryParams,
      focusNames: queryParams1,
      KOL_Profile_Portal__c_Name: this.KOL_Portal_Name,
      congressId: Object.keys(congressFilter).length !== 0 ? congressFilter : '',
      pageSize: 100,
      pageNumber: 1,
      userId: this.KOL_UserID
    }
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/fetchEngagementsAll?KOL_Portal_Name=${this.KOL_Portal_Name}`,
        body
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchEngagementsAll: ", error);
      throw error;
    }
  }

  public async fetchEngagements(profileId?: string, queryParams?: string[], queryParams1?: any[], FFS__cValue?: string) {
    const params = new URLSearchParams();
    params.append('profileId', profileId);
    params.append('KOL_Portal_Name', this.KOL_Portal_Name);
    params.append('userId', this.KOL_UserID)    
    if (FFS__cValue !== undefined && FFS__cValue !== ""){
      params.append('ffs', FFS__cValue)
    }
    (queryParams ?? []).forEach(name => params.append('brandsFilters', name));
    (queryParams1 ?? []).forEach(name => params.append('brandsFocus', name));
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEngagements`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error fetchEngagements: ", error);
      throw error;
    }
  }

  public async fetchGattexAttendees() {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchGattexAttendees`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchGattexAttendees:", error);
      throw error;
    }     
  }

  public async fetchGattexAttendeesStage() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchGattexAttendeesStage`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchGattexAttendeesStage:", error);
      throw error;
    } 
  }

  public async fetchCalendarEvents(engagementId?: string) {
    // Start_Date, End_Date, Id, Location, Details all needed for update request
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCalendarEvents?KOL_Profile_Portal__pc=${this.KOL_Profile_Portal__pc}&engagementId=${engagementId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchCalendarEvents: ", error);
      throw error;
    }
  }

  public async fetchCreatedBy(engagementId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCreatedBy`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchCreatedBy:", error);
      throw error;
    } 
  }

  public async fetchAlerts() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchAlerts/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchAlerts", error);
      throw error;
    }
  }

  public async fetchTags(engagementId?: string) {
    try {
      var parameter = engagementId ?? '';
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTags?engagementId=${parameter}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async updateTopicsTags(tagging: ITag) {
    try {
      await axios.put(`${this.KOL_Api_Url}/updateTopicsTags`, {
        Id: tagging.Id,
        Brand_Focus__c: tagging.Brand_Focus__c,
        Disease_Focus__c: tagging.Disease_Focus__c,
        Takeda_Insight__c: tagging.Takeda_Insight__c,
        No_Topics_to_Note__c: tagging.No_Topics_to_Note__c
      });
      return "Success";
    } catch (error) {
      console.error("Error updating topics:", error);
      return "Error";
    }
  }

  // KOL SEARCH PAGE START HERE --------------------------------------------------------------------------------------

  public async fetchFavorites(userId?: string, formSelect?: any) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchFavorites?userId=${userId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetch Favorites:", error);
      throw error;
    }
  }

  public async postFavorites(
    profileId: string,
    employeeId: string,
    isFav: boolean
  ) {

    console.log('public async postFavorites(', profileId, employeeId, isFav)
    try {
      const response = await axios.post(`${this.KOL_Api_Url}/postFavorites?isFav=${isFav}&profileId=${profileId}&ownerId=${this.KOL_UserID}&employeeId=${employeeId ?? ''}`
      );
      return response.data;
    } catch (error) {
      console.error("Error postFavorites", error);
      throw error;
    }
  }

  public async postFavoritesNew(
    profileId: string,
    employeeId: string,
    isFav: boolean
  ) {
    try {
      const response = await axios.post(`${this.KOL_Api_Url}/postFavoritesNew?isFav=${isFav}&profileId=${profileId}&ownerId=${this.KOL_UserID}&employeeId=${employeeId ?? ''}`
      );
      return response.data;
    } catch (error) {
      console.error("Error postFavorites", error);
      throw error;
    }
  }

  public async searchKOLProfiles(query: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/searchKOLProfiles?KOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}&search=${query}`);
      return response.data;
    } catch (error) {
      console.error("Error searchKOLProfiles:", error);
      throw error;
    }
  }



  public async fetchKOLProfiles(
    filters?: { [key: string]: any },
    formSelect?: any
  );
  public async fetchKOLProfiles(filters?: string, formSelect?: any);
  public async fetchKOLProfiles(filters?: any, formSelect?: any) {
    console.log("from fetchKOLProfiles");
    console.log("filtersfilters", filters);

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKOLProfiles?id=${filters}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchKOLProfiles", error);
      throw error;
    }
  }

  public async fetchProfilesDataNetwork(brands?: any[]): Promise<any> {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchProfilesDataNetwork?KOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}&Brand_Master_Names=${brands}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchProfilesDatesNetwork:", error);
      throw error;
    }
  }

  public async fetchProfilesData(queryParams: string[], companycheck: string) {
    try {
      if (queryParams.length !== 0) {
        if (companycheck == "PCYC") {
          const response = await axios.put(
            `${this.KOL_Api_Url}/fetchProfilesData?KOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}`,
            [BRAND1]
          );
          return response.data;
        } else {
          const response = await axios.put(
            `${this.KOL_Api_Url}/fetchProfilesData?KOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}`,
            queryParams
          );
          return response.data;
        }
      }
    } catch (error) {
      console.error("Error fetching total engagements:", error);
      throw error;
    }
  }

  public async fetchEmployeeFavorites(userId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEmployeeFavorites`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchEmployeeFavorites:", error);
      throw error;
    } 
  }

  public async fetchTotalEngagements(profileId?: string, formSelect?: string) {
    try {
      let url = `${this.KOL_Api_Url}/fetchTotalEngagements?profilePotal=${this.KOL_Profile_Portal__pc}`;

      if (profileId) url += `&profileId=${profileId}`;

      if (formSelect) url += `&formSelect=${formSelect}`;

      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching total engagements:", error);
      throw error;
    }
  }

  public async fetchTotalMeetings(profileId?: string, formSelect?: any) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTotalMeetings/${this.KOL_Profile_Portal__pc}?profileId=${profileId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchTotalMeetings", error);
      throw error;
    }
  }

  public async fetchTotalEvents(profileId?: string, formSelect?: any) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTotalEvents?profileId=${profileId}&formSelect=${formSelect}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async fetchMedia(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchMedia?profileId=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching media:", error);
      throw error;
    }
  }


  public async fetchCongressActivity(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCongressActivity?profileId=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchCongressActivity", error);
      throw error;
    }
  }
  public async fetchBiography(profileId?: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchBiography/${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchBiography:", error);
      throw error;
    }
  }

  public async UploadCV(bodyString, fileName, RecordID) {
    try {
      var data = {
        body: bodyString,
        name: fileName,
        parentId: RecordID,
      };
      const response = await axios.post(`${this.KOL_Api_Url}/uploadCV`, data);
      return response.data;
    } catch (error) {
      console.error("Error uploading CV:", error);
      throw error;
    }
  }

  public async DownloadCV(RecordID) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/DownloadCV/${RecordID}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching DownloadCV:", error);
      throw error;
    }
  }

  public async fetchAssociations(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchAssociations?profileId=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching permissions:", error);
      throw error;
    }
  }


  public async fetchSphereOfInfluence(profileId?: string,) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchSphereOfInfluence?profileId=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetch SphereOfInfluence:", error);
      throw error;
    }
  }


  public async fetchResearh(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchResearh`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchResearh:", error);
      throw error;
    } 
  }

  public async fetchTopConnectionsInstitutions(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTopConnectionsInstitutions/${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching TopConnectionsInstitutions", error);
      throw error;
    }
  }

  public async getInstitutions(profileId: string, filters?: any) {
    console.log("filters>>>>", filters)
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getInstitutions/${profileId}/${this.KOL_Portal_Name}`,
        filters);
      return response.data;
    } catch (error) {
      console.error("Error getInstitutions:", error);
      throw error;
    }
  }

public async getConnections(profileId: string, filters?: any) {
  try {
    const response = await axios.put(`${this.KOL_Api_Url}/getConnections/${profileId}/${this.KOL_Profile_Portal__pc}`,
      filters);
    return response.data;
  } catch (error) {
    console.error("Error getInstitutions:", error);
    throw error;
  }

}
  public async fetchMeetings(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchMeetings?profileId=${profileId}&profilePortal=${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching meetings", error);
      throw error;
    }
  }

  public async fetchToolTipMeetings(MasterEngagementIds: string[]) {
    console.log('from fetchToolTipMeetings');
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchToolTipMeetings?KOL_Profile_Portal__pc=${this.KOL_Profile_Portal__pc}&MasterEngagementIds=${MasterEngagementIds}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching ToolTipMeetings", error);
      throw error;
    }
  }

  public async fetchEvents(profileId?: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEvents?profileId=${profileId}&KOL_Profile_Portal__pc=${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchEvents", error);
      throw error;
    }
  }

  public async fetchTopConnections(institutions: string[]) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTopConnections?institutions=${institutions}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchTopConnections:", error);
      throw error;
    }
  }

  public async fetchNotes(profileId?: string, Division__c: string = '') {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchNotes?profileId=${profileId}&division=${Division__c}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching notes", error);
      throw error;
    }
  }

  public async postNotes(note: INote) {
    var postNoteData = {
      KOL__c: note.KOL__c,
      Title__c: note.Title__c,
      Body__c: note.Body__c,
      Division__c: note.Division__c,
      OwnerId: this.KOL_UserID
    };

    const postNoteDataResponse = await axios.post(`${this.KOL_Api_Url}/postNotes`, postNoteData);

    if (note.Division__c == "Admin") {
      return await this.fetchNotes(note.KOL__c);
    } else if (
      note.Division__c == "Commercial" ||
      note.Division__c == "Medical"
    ) {
      return await this.fetchNotes(note.KOL__c, note.Division__c);
    }
  }

  public async postNotesInsitution(note: INote) {
    console.log("profile", note.KOL__c);
    var postNoteData = {
      KOL__c: note.KOL__c,
      Title__c: note.Title__c,
      Body__c: note.Body__c,
      Division__c: note.Division__c,
      Institution_Note__c: true,
      Care_Team_Note__c: false,
      OwnerId: this.KOL_UserID
    };

    const postNoteDataResponse = await axios.post(`${this.KOL_Api_Url}/postNotesInsitution`, postNoteData);

    if (note.Division__c == "Admin") {
      return await this.fetchNotesInsitution(note.KOL__c);
    } else if (
      note.Division__c == "Commercial" ||
      note.Division__c == "Medical"
    ) {
      return await this.fetchNotesInsitution(note.KOL__c, note.Division__c);
    }
  }

  async fetchNotesInsitution(profileId: string, Division__c: string = '') {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchNotesInsitution?profileId=${profileId}&division=${Division__c}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching notes", error);
      throw error;
    }
  }

  public async postNotesCare(note: INote) {
    var noteData =
    {
      KOL__c: note.KOL__c,
      Title__c: note.Title__c,
      Body__c: note.Body__c,
      Division__c: note.Division__c,
      Institution_Note__c: false,
      Care_Team_Note__c: true,
      OwnerId: this.KOL_UserID
    };

    const response = await axios.post(`${this.KOL_Api_Url}/postNotesCare?profileId=${note.KOL__c}&?division=${note.Division__c}`, noteData);

    if (note.Division__c == "Admin") {
      return await this.fetchNotesCares(note.KOL__c);
    } else if (
      note.Division__c == "Commercial" ||
      note.Division__c == "Medical"
    ) {
      return await this.fetchNotesCares(note.KOL__c, note.Division__c);
    }
  }

  async fetchNotesCares(profileId: string, Division__c: string = '') {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchNotesCares?profileId=${profileId}&?division=${Division__c}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching notes", error);
      throw error;
    }
  }

  public async deleteNotes0(Id: string, profileId: string, division: string) {
    const response = await axios.post(`${this.KOL_Api_Url}/deleteNotes0?id=${Id}&profileId=${profileId}&division=${division}`);

    if (division == "Admin") {
      return await this.fetchNotes(profileId);
    } else if (division == "Commercial" || division == "Medical") {
      return await this.fetchNotes(profileId, division);
    }
  }

  public async deleteNotes(Id: string, profileId: string, division: string) {
    const response = await axios.post(`${this.KOL_Api_Url}/deleteNotes?id=${Id}&profileId=${profileId}&division=${division}`);


    if (division == "Admin") {
      return await this.fetchInstitutionNotes(profileId);
    } else if (division == "Commercial" || division == "Medical") {
      return await this.fetchInstitutionNotes(profileId, division);
    }
  }

  public async deleteNotes1(
    Id: string,
    profileId: string,
    Division__c: string
  ) {
    const response = await axios.post(`${this.KOL_Api_Url}/deleteNotes?id=${Id}&profileId=${profileId}&division=${Division__c}`);

    if (Division__c == "Admin") {
      return this.fetchCareNotes(profileId);
    } else if (Division__c == "Commercial" || Division__c == "Medical") {
      return this.fetchCareNotes(profileId, Division__c);
    }
  }

  //
  public async postExpereinceInterest(profile: IFlatProfile, flag: boolean) {
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/postExpereinceInterest?flag=${flag}`,
        {
          flatProfile: profile,
          brands: [BRAND1, BRAND2, BRAND3]
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error postExpereinceInterest: ", error);
      throw error;
    }
  }
  public async postProfile(profile: IAddProfile) {
    console.log('from postProfile');

    try {
      let addProfileResult = await axios.post(`${this.KOL_Api_Url}/postProfile?
        firstName=${profile.fname}
        &lastName=${profile.lname}
        &affiliation__pc=${profile.affiliation}
        &personMailingCity=${profile.city}
        &personMailingState=${profile.state}
        &degree__pc=${profile.degree}
        &primary_Title_Position__pc=${profile.title}
        &specialty__c=${profile.speciality}
        &recordTypeId=${this.RecordTypeId}
        &kOL_Profile_Portal__pc=${this.KOL_Profile_Portal__pc}`);
    } catch (error) {
      console.error('error postProfile');
    }
  }

  // KOL SEARCH PAGE END HERE --------------------------------------------------------------------------------------

  // SUPPORT PAGE

  public async fetchSupportAccountManager() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchSupportAccountManager`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchSupportAccountManager:", error);
      throw error;
    }     
  }

  public async fetchSupportMeetingManager() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchSupportMeetingManager`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchSupportMeetingManager:", error);
      throw error;
    } 
  }

  public async postSupportIssue(textAreaValue, user) {
    var todaysDate = new Date().toISOString().slice(0, 10);
    var queryString = ` 
        client_Email__c=${user.Email}
        &description__c=${textAreaValue}
        &eHG_Contact__c=${user.FirstName} ${user.LastName}
        &kOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}
        &date_of_request__c=${todaysDate}
        &clientEmail=${user.Email}
        &ownerId=${this.KOL_UserID}`;        
    try {
      const response = await axios.post(`${this.KOL_Api_Url}/postSupportIssue?${queryString}`);
      //return response.data;
      return "Success";
    } catch (error) {
      console.error("Error creating support ticket", error);
      throw error;
    }
  }

  public async fetchResourcesMaterial() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchResourcesMaterial`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchResourcesMaterial:", error);
      throw error;
    }
  }

  public async fetchKOLsFromMasterEngagement(masterEngagementId?: string) {
    //fetch all the kme attendees (hcp & employee) and make them editable    
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKOLsFromMasterEngagement?masterEngagementId=${masterEngagementId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching KOLsFromMasterEngagement:", error);
      throw error;
    }
  }

  public async fetchEngagementsData0(masterEngagementId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEngagementsData0`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchEngagementsData0:", error);
      throw error;
    } 
  }

  public async fetchEngagementsData(masterEngagementId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEngagementsData`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchEngagementsData:", error);
      throw error;
    } 
  }

  public async updateMasterEngagements(engage: IEngagement) {
    //update master engagements, not kols- that'll be seperate
    console.log("*******", engage);
    try {
      await axios.put(`${this.KOL_Api_Url}/updateMasterEngagements`, {
        Id: engage.Id,
        End_Date_Time__c: engage.End_Date_Time__c,
        Engagement_Date__c: engage.Start_Date_Time__c,
        KOL_Profile_Portal__c: `${this.KOL_Profile_Portal__pc}`,
        Location__c: engage.Location__c,
        Notes_Details__c: engage.Notes_Details__c,
        Start_Date_Time__c: engage.Start_Date_Time__c
      });
    } catch (error) {
      console.error("Error updating topics:", error);
      throw error;
    }
  }

  public async updateTheKOLstatus(list: IMasterEngagement[]) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/updateTheKOLstatus`);
      return response.data;
    } catch (error) {
      console.error("Error fetching updateTheKOLstatus:", error);
      throw error;
    } 
  }

  private preparePostObject = (master: IMasterEngagement): any => {
    const postObject = {
      Id: master.Id,
      Master_Engagement__c: master.Master_Engagement__c,
      Status__c: master.Status__c,
    };
    if (master?.Engagement_Type__c !== "1:1 Meeting") {
      postObject["Attendee_Role__c"] = master.Attendee_Role__c;
    }
    return postObject;
  };

  public async updateTheKOLstatusEach(data) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/updateTheKOLstatusEach`);
      return response.data;
    } catch (error) {
      console.error("Error fetching updateTheKOLstatusEach:", error);
      throw error;
    } 
  }
  // Resources Page - get more resources data

  public async deleteKolAttendees(masterEngagementId: string) {
    try {
      const response = await axios.delete(`${this.KOL_Api_Base_Url}/kolattendees/${masterEngagementId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting kol attendees:", error);
      throw error;
    }
  }

  public async deleteKolEngagements(masterEngagementId: string) {
    try {
      const response = await axios.delete(`${this.KOL_Api_Base_Url}/kolengagements/${masterEngagementId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting kol engagements:", error);
      throw error;
    }
  }

  public async updateMasterEngagementsAll(formData: any) {
    try {
      const response = await axios.put(`${this.KOL_Api_Base_Url}/engagements/${formData.Id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating master engagement:", error);
      throw error;
    }
  }

  public async updateEngagements(formData, gattexAttendees, id) {
    console.log("formDAta>>>>", formData);
    
    await this.deleteKolAttendees(id);
    await this.deleteKolEngagements(id);

    const newDatetime = new Date(formData.finalForm.subData.Start_Date_Time__c);
    const newEndDatetime = new Date(
      formData.finalForm.subData.End_Date_Time__c
    );

    const engToMeeting = (type) => {
      switch (type) {
        case "1:1 Engagement":
          return "1:1 Meeting";
        default:
          return type;
      }
    };
    console.log("🚀 ~ engagementResult formData-->", formData);
    console.log("🚀 ~ engagementResult ID-->", id);
    let engagementResult;

    let brand_name = "";
    if (
      formData.finalForm.subData.focus == "Imbruvica" ||
      formData.finalForm.subData.focus == "LL Unbranded" ||
      formData.finalForm.subData.focus == "Leukemia/Lymphoma Franchise" ||
      formData.finalForm.subData.focus == "Nipocalimab" ||
      formData.finalForm.subData.focus == "Pipeline"
    ) {
      brand_name = `${BRAND1}`;
    } else if (

      formData.finalForm.subData.focus == "Erleada" ||
      formData.finalForm.subData.focus == "PF Unbranded" ||
      formData.finalForm.subData.focus == "Prostate Franchise " ||
      formData.finalForm.subData.focus == "Niraparib"
    ) {
      brand_name = `${BRAND3}`;
    } else {
      brand_name = `${BRAND2}`;
    }

    let check_topic = 0;
    let check_topic_1 = false;

    if (

      formData.finalForm.subData.focus == "Imbruvica" ||
      formData.finalForm.subData.focus == "Leukemia/Lymphoma Franchise" ||
      formData.finalForm.subData.focus == "Bispecific" ||
      formData.finalForm.subData.focus == "Carvykti" ||
      formData.finalForm.subData.focus == "Darzalex" ||
      formData.finalForm.subData.focus == "Erleada" ||
      formData.finalForm.subData.focus == "Prostate Franchise" ||
      formData.finalForm.subData.focus == "Niraparib"
    ) {
      check_topic = 0;
    } else if (formData.finalForm.subData.focus == "MM Portfolio Brand") {
      check_topic = 0;
      check_topic_1 = true;
    } else {
      check_topic = 1;
    }

    let masterId;
    let focusId;
    let topicId;

    await this.getMasterId(formData.finalForm.subData.alignment).then(async (data: any) => {
      console.log("masterId>>>>>", data?.records[0]?.Id);
      masterId = data?.records[0]?.Id;
      await this.getFocusId(
        data?.records[0]?.Id,
        formData.finalForm.subData.focus,
        //check_topic == 1
        formData.finalForm.subData.focus_Type=="diseaseFocus"
          ? "Disease Focus":"Brand Focus"
        //check_topic == 1 ? "Disease Focus" : "Brand Focus"
      ).then(async (data1: any) => {
        console.log("focusId>>>>>", data1?.records[0]?.Id);
        focusId = data1?.records[0]?.Id;
        await this.getTopicId(
          data1?.records[0]?.Id,
          formData.finalForm.subData.topic_title
        ).then((data2: any) => {
          console.log("topicId>>>>>", data2?.records[0]?.Id);
          topicId = data2?.records[0]?.Id;
        });
      });
    });

    console.log("masterId", masterId);
    console.log("focusId", focusId);
    console.log("topicId", topicId);

    if (formData.finalForm.subData.category === "Congress") {
      //congress

      if (
        //check_topic == 1
        formData.finalForm.subData.focus_Type=="diseaseFocus"
        ) {
          engagementResult = await this.updateMasterEngagementsAll
          ({
            Id: id,
            Name: formData.value,
            Engagement_Date__c: newDatetime,
            Start_Date_Time__c: newDatetime,
            End_Date_Time__c: newEndDatetime,
            KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
            Type__c: formData.value,
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
            Congress__c: formData.finalForm.subData.Congress__c,
            Location__c: formData.finalForm.subData.Location__c,
            Status__c: formData.finalForm.subData.status,
            Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
            Format__c: formData.finalForm.subData.format,
            Location_Type__c: formData.finalForm.subData.location_type,
            Meal__c: formData.finalForm.subData.meal,
            Topic__c: formData.finalForm.subData.topic_title,
            Brand__c: formData.finalForm.subData.alignment,
            Brand_Master__c: masterId,

            Focus_Master__c: focusId,
            Topic_Master__c: topicId,
            Disease_Focus__c: formData.finalForm.subData.focus,
          });
      } else {
        engagementResult = await this.updateMasterEngagementsAll({
          Id: id,
          Name: formData.value,
          Engagement_Date__c: newDatetime,
          Start_Date_Time__c: newDatetime,
          End_Date_Time__c: newEndDatetime,
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          Congress__c: formData.finalForm.subData.Congress__c,
          Location__c: formData.finalForm.subData.Location__c,
          Status__c: formData.finalForm.subData.status,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Format__c: formData.finalForm.subData.format,
          Location_Type__c: formData.finalForm.subData.location_type,
          Meal__c: formData.finalForm.subData.meal,
          Topic__c: formData.finalForm.subData.topic_title,
          Brand__c: formData.finalForm.subData.alignment,
          Brand_Master__c: masterId,

          Focus_Master__c: focusId,
          Topic_Master__c: topicId,
          Brand_Focus__c: formData.finalForm.subData.focus,
        });
      }
    } else {
      //stand-alone

      if (
        formData.finalForm.subData.focus_Type=="diseaseFocus"
      //check_topic == 1
      )
       {
        engagementResult = await this.updateMasterEngagementsAll({
          Id: id,
          Name: formData.value,
          Engagement_Date__c: newDatetime,
          Start_Date_Time__c: newDatetime,
          End_Date_Time__c: newEndDatetime,
          Congress__c: "",
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          Location__c: formData.finalForm.subData.Location__c,
          Status__c: formData.finalForm.subData.status,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Format__c: formData.finalForm.subData.format,
          Location_Type__c: formData.finalForm.subData.location_type,
          Meal__c: formData.finalForm.subData.meal,
          Topic__c: formData.finalForm.subData.topic_title,
          Brand__c: formData.finalForm.subData.alignment,
          Brand_Master__c: masterId,

          Focus_Master__c: focusId,
          Topic_Master__c: topicId,
          Disease_Focus__c: formData.finalForm.subData.focus,
        });
      } else {
        engagementResult = await this.updateMasterEngagementsAll({
          Id: id,
          Name: formData.value,
          Engagement_Date__c: newDatetime,
          Start_Date_Time__c: newDatetime,
          End_Date_Time__c: newEndDatetime,
          Congress__c: "",
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          Location__c: formData.finalForm.subData.Location__c,
          Status__c: formData.finalForm.subData.status,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Format__c: formData.finalForm.subData.format,
          Location_Type__c: formData.finalForm.subData.location_type,
          Meal__c: formData.finalForm.subData.meal,
          Topic__c: formData.finalForm.subData.topic_title,
          Brand__c: formData.finalForm.subData.alignment,
          Brand_Master__c: masterId,

          Focus_Master__c: focusId,
          Topic_Master__c: topicId,
          Brand_Focus__c: formData.finalForm.subData.focus,
        });
      }
    }

    await Promise.all(
      formData.finalForm.kolnewData.map(async (x) => {
        if (x.KOLAccount__c === undefined){
          await  this.addNewKOL(
            x,
            id,
            formData.finalForm.subData.Engagement_Type__c,
            newDatetime,
            masterId,
            formData.value,
            formData.finalForm.subData.Notes_Details__c,
          );
        }
        else {
          await this.createKolEngagement({
            Master_Engagement__c: id,
            KOLAccount__c: x.KOLAccount__c,
            Attendee_Role__c: x.Attendee_Role__c,
            Engagement_Type__c:formData.finalForm.subData.Engagement_Type__c,
            Engagement_Date__c: newDatetime,
            KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
            Status__c: x.Status__c,
            Type__c: formData.value,
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,        
          });
        }
       })
    );


    if (formData.finalForm.subData.Attendee_Type__c != "") {
      const strArr = formData.finalForm.subData.Attendee_Type__c;
      await Promise.all(
        strArr.map(async (item) => {
          console.log("address--->", item);
  
          await this.createKolAttendee({
            Master_Engagement__c: id,
            Employee__c: item.id,
            Attendee_Role__c: item.Attendee_Role__c,
            Send_Invite_Email__c: item.email,
            Host_Email__c: formData.finalForm.subData.Host_Email__c,
            Host_Name__c: formData.Host_Name__c,
            Email_Subject_Line__c: formData.Email_Subject_Line__c,
            userId: this.KOL_UserID,
            Attendee_Name__c: item.name,
            Type__c: formData.value ?? '',
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          });
        })        
      );

    }

    if (formData.finalForm.subData.Attendee_Leadership__c.length != 0) {
      const strArr1 = formData.finalForm.subData.Attendee_Leadership__c
      if (strArr1.length == 1) {
        await this.createKolAttendee({
          Leadership_Attendee__c: true,
          Master_Engagement__c: id,
          Employee__c: formData.finalForm.subData.Attendee_Leadership__c[0].value,
          Send_Invite_Email__c: formData.finalForm.subData.Attendee_Leadership__c[0].email,
          Host_Email__c: formData.finalForm.subData.Host_Email__c,
          Host_Name__c: formData.Host_Name__c,
          Email_Subject_Line__c: formData.Email_Subject_Line__c,
          userId: this.KOL_UserID,
          Attendee_Name__c: formData.finalForm.subData.Attendee_Leadership__c[0].name,
          Type__c: formData.value ?? '',
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
        });
      } else {
        await Promise.all(
          strArr1.map(async (item) => {
            console.log("address1--->", item);
  
           await  this.createKolAttendee({
              Leadership_Attendee__c: true,
              Master_Engagement__c: id,
              Employee__c: item.value,
              Send_Invite_Email__c: item.email,
              Host_Email__c: formData.finalForm.subData.Host_Email__c,
              Host_Name__c: formData.Host_Name__c,
              Email_Subject_Line__c: formData.Email_Subject_Line__c,
              userId: this.KOL_UserID,
              Attendee_Name__c: item.name,
              Type__c: formData.value ?? '',
              Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
            });
          })
        );

      }
    }

    await Promise.all(
      formData.finalForm.kmeAryData.map(async (x) => {
        console.log("x---->", x);
        await this.createKolEngagement({
          Master_Engagement__c: id,
          KOLAccount__c: x.KOLAccount__c,
          Attendee_Role__c: x.Attendee_Role__c,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Engagement_Date__c: newDatetime,
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Status__c: x.Status__c,
          Send_Invite_Email__c: x.email,
          Host_Email__c: formData.finalForm.subData.Host_Email__c,
          Host_Name__c: formData.Host_Name__c,
          Email_Subject_Line__c: formData.Email_Subject_Line__c,
          userId: this.KOL_UserID,
          Type__c: formData.value ?? '',
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
        });
      })
    )
  }

  public async validateNewKol(lastName, firstName) {

    const params = new URLSearchParams();

    params.append('lastName', lastName)
    params.append('firstName', firstName);
    params.append('KOL_Profile_Portal__pc', this.KOL_Profile_Portal__pc);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/validateNewKOL`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error validateNewKol: ", error);
      throw error;
    }

  }

  public async addNewKOL(x, engagementId, type, date_add, masterId, Type__c, Notes_Details__c) {

    try {
      var data = {
        FirstName: x.firstName,
        LastName: x.lastName,
        RecordTypeId: this.RecordTypeId,
        Account__c: this.Account__c,
        Write_In_KOL__pc: true,
        Degree__pc: x.credentails,
        PersonTitle: x.title,
        Primary_Title_Affiliation__pc: x.institution,
        PersonMailingCity: x.city,
        PersonMailingState: x.state,
        KOL_Profile_Portal__pc: this.KOL_Profile_Portal__pc
      };

      const createKolResponse = await axios.post(`${this.KOL_Api_Url}/addNewKOL`, data);

      await axios.post(`${this.KOL_Api_Url}/createkolbrand`, {
        kOL__c: createKolResponse.data.Id,
        brand_Master__c: masterId,
        userId: this.KOL_UserID,
        Tier__c: 'Other',
        Tll_Name: x.Tll_Name ?? ''
      });

      await this.createKolEngagement({
        Master_Engagement__c: engagementId,
        KOLAccount__c: createKolResponse.data.Id,
        Attendee_Role__c: x.Attendee_Role__c,
        Engagement_Type__c: type,
        Engagement_Date__c: date_add,
        KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
        Status__c: x.Status__c,
        Type__c: Type__c,
        Notes_Details__c: Notes_Details__c,        
      });

      return createKolResponse.data;
    } catch (error) {
      console.error("Error addNewKOL:", error);
      throw error;
    }
  }

  public async createEngagement(formData: any) {
    try {
      const response = await axios.post(`${this.KOL_Api_Base_Url}/engagements`, formData);
      return response.data;
    } catch (error) {
      console.error("Error creating engagement:", error);
      throw error;
    }
  }

  public async createKolAttendee(formData: any) {
    try {
      const response = await axios.post(`${this.KOL_Api_Base_Url}/kolattendees`, formData);
      return response.data;
    } catch (error) {
      console.error("Error creating kol attendee:", error);
      throw error;
    }
  }

  public async createKolEngagement(formData: any) {
    try {
      const response = await axios.post(`${this.KOL_Api_Base_Url}/kolengagements`, formData);
      return response.data;
    } catch (error) {
      console.error("Error creating kol engagement:", error);
      throw error;
    }
  }

  public async addEngagements(formData, gattexAttendees) {
    console.log("formData>>>>>>", formData);
    console.log("addEngagement>>>>>", formData);
    const newDatetime = moment(formData.finalForm.subData.Start_Date_Time__c).format("YYYY-MM-DDTHH:mm");
    const newEndDatetime = moment(formData.finalForm.subData.End_Date_Time__c).format("YYYY-MM-DDTHH:mm");
    console.log("after date--->", newDatetime);

    const engToMeeting = (type) => {
      switch (type) {
        case "1:1 Engagement":
          return "1:1 Meeting";
        default:
          return type;
      }
    };
    console.log("🚀 ~ engagementResult formData-->", formData);
    console.log(
      "🚀  file: sfdcApi.tsx  line 1980  SfdcApi  ",
      formData.finalForm.subData.Attendee_Leadership__c
    );
    console.log(
      "🚀  file: sfdcApi.tsx  line 1980  SfdcApi  ",
      formData.finalForm.subData.Attendee_Type__c
    );
    console.log(
      "🚀  file: sfdcApi.tsx  line 1980  SfdcApi  ",
      formData.finalForm.subData.Congress__c
    );

    let engagementResult;

    let brand_name = "";
    let check_topic = 0;
    let check_topic_1 = false;
    if (

      formData.finalForm.subData.focus == "Imbruvica" ||
      formData.finalForm.subData.focus == "LL Unbranded" ||
      formData.finalForm.subData.focus == "Leukemia/Lymphoma Franchise" ||
      formData.finalForm.subData.focus == "Nipocalimab" ||
      formData.finalForm.subData.focus == "Pipeline"
    ) {
      brand_name = `${BRAND1}`;
    } else if (

      formData.finalForm.subData.focus == "Erleada" ||
      formData.finalForm.subData.focus == "PF Unbranded" ||
      formData.finalForm.subData.focus == "Prostate Franchise " ||
      formData.finalForm.subData.focus == "Niraparib"
    ) {
      brand_name = `${BRAND3}`;
    } else {
      brand_name = `${BRAND2}`;
    }

    if (

      formData.finalForm.subData.focus == "Imbruvica" ||
      formData.finalForm.subData.focus == "Leukemia/Lymphoma Franchise" ||
      formData.finalForm.subData.focus == "Bispecific" ||
      formData.finalForm.subData.focus == "Carvykti" ||
      formData.finalForm.subData.focus == "Darzalex" ||
      formData.finalForm.subData.focus == "Erleada" ||
      formData.finalForm.subData.focus == "Prostate Franchise" ||
      formData.finalForm.subData.focus == "Niraparib"
    ) {
      check_topic = 0;
    } else if (formData.finalForm.subData.focus == "MM Portfolio Brand") {
      check_topic = 0;
      check_topic_1 = true;
    } else {
      check_topic = 1;
    }

    let masterId;
    let focusId;
    let topicId;

    await this.getMasterId(formData.finalForm.subData.alignment).then(async (data: any) => {
      console.log("masterId>>>>>", data?.records[0]?.Id);
      masterId = data?.records[0]?.Id;
      await this.getFocusId(
        data?.records[0]?.Id,
        formData.finalForm.subData.focus,
        //check_topic == 1
        formData.finalForm.subData.focus_Type=="diseaseFocus"
          ? "Disease Focus":"Brand Focus"
        
        //check_topic == 1 ? "Disease Focus" : "Brand Focus"
      ).then(async (data1: any) => {
        console.log("focusId>>>>>", data1?.records[0]?.Id);
        focusId = data1?.records[0]?.Id;
        await this.getTopicId(
          data1?.records[0]?.Id,
          formData.finalForm.subData.topic_title
        ).then((data2: any) => {
          console.log("topicId>>>>>", data2?.records[0]?.Id);
          topicId = data2?.records[0]?.Id;
        });
      });
    });

    //return;

    console.log("masterId", masterId);
    console.log("focusId", focusId);
    console.log("topicId", topicId);

    if (formData.finalForm.subData.category === "Congress") {
      //congress

      console.log("newDatetime", newDatetime);

      if (
        //check_topic == 1
        formData.finalForm.subData.focus_Type=="diseaseFocus"
        ) {
          engagementResult = await this.createEngagement({
            Name: formData.value,
            Engagement_Date__c: newDatetime,
            Start_Date_Time__c: newDatetime,
            End_Date_Time__c: newEndDatetime,
            KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
            Type__c: formData.value,
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
            Congress__c: formData.finalForm.subData.Congress__c,
            Location__c: formData.finalForm.subData.Location__c,
            Status__c: formData.finalForm.subData.status,
            Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
            Format__c: formData.finalForm.subData.format,
            Location_Type__c: formData.finalForm.subData.location_type,
            Meal__c: formData.finalForm.subData.meal,
            Topic__c: formData.finalForm.subData.topic_title,
            Brand__c: formData.finalForm.subData.alignment,
            Brand_Master__c: masterId,
  
            Focus_Master__c: focusId,
            userId: this.KOL_UserID,
            Topic_Master__c: topicId,
            Disease_Focus__c: formData.finalForm.subData.focus,
          });
        } else {
          console.log("newDatetime", newDatetime);
  
          engagementResult = await this.createEngagement({
            Name: formData.value,
            Engagement_Date__c: newDatetime,
            Start_Date_Time__c: newDatetime,
            End_Date_Time__c: newEndDatetime,
            KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
            Type__c: formData.value,
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
            Congress__c: formData.finalForm.subData.Congress__c,
            Location__c: formData.finalForm.subData.Location__c,
            Status__c: formData.finalForm.subData.status,
            Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
            Format__c: formData.finalForm.subData.format,
            Location_Type__c: formData.finalForm.subData.location_type,
            Meal__c: formData.finalForm.subData.meal,
            Topic__c: formData.finalForm.subData.topic_title,
            Brand__c: formData.finalForm.subData.alignment,
            Brand_Master__c: masterId,
  
            Focus_Master__c: focusId,
            userId: this.KOL_UserID,
            Topic_Master__c: topicId,
            Brand_Focus__c: formData.finalForm.subData.focus,
          });
        }
    } else {
      //stand-alone
      console.log("newDatetime", newDatetime);
      if (
        //check_topic == 1
        formData.finalForm.subData.focus_Type=="diseaseFocus"
        ) {
          engagementResult = await this.createEngagement({
            Name: formData.value,
            Engagement_Date__c: newDatetime,
            Start_Date_Time__c: newDatetime,
            End_Date_Time__c: newEndDatetime,
            KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
            Type__c: formData.value,
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
            Location__c: formData.finalForm.subData.Location__c,
            Status__c: formData.finalForm.subData.status,
            Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
            Format__c: formData.finalForm.subData.format,
            Location_Type__c: formData.finalForm.subData.location_type,
            Meal__c: formData.finalForm.subData.meal,
            Topic__c: formData.finalForm.subData.topic_title,
            Brand__c: formData.finalForm.subData.alignment,
            Brand_Master__c: masterId,
  
            Focus_Master__c: focusId,
            userId: this.KOL_UserID,
            Topic_Master__c: topicId,
            Disease_Focus__c: formData.finalForm.subData.focus,
          });
        } else {
          console.log("newDatetime", newDatetime);
          engagementResult = await this.createEngagement({
            Name: formData.value,
            Engagement_Date__c: newDatetime,
            Start_Date_Time__c: newDatetime,
            End_Date_Time__c: newEndDatetime,
            KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
            Type__c: formData.value,
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
            Location__c: formData.finalForm.subData.Location__c,
            Status__c: formData.finalForm.subData.status,
            Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
            Format__c: formData.finalForm.subData.format,
            Location_Type__c: formData.finalForm.subData.location_type,
            Meal__c: formData.finalForm.subData.meal,
            Topic__c: formData.finalForm.subData.topic_title,
            Brand__c: formData.finalForm.subData.alignment,
            Brand_Master__c: masterId,
  
            Focus_Master__c: focusId,
            userId: this.KOL_UserID,
            Topic_Master__c: topicId,
            Brand_Focus__c: formData.finalForm.subData.focus,
          });
        }
    }

    const engagementId = engagementResult["id"];
    console.log(
      "🚀 ~ file: sfdcApi.tsx ~ line 1852-new ~ SfdcApi ~ awaitthis.conn.sobject ~ formData",
      formData
    );
    console.log("engagementId", engagementId);
    console.log("formData.finalform.kmeAryData", formData.finalForm.kmeAryData);
    console.log("engToMeeting(formData.value)", engToMeeting(formData.value));
    console.log("newDatetime", newDatetime);
    console.log("this.KOL_Profile_Portal__pc", this.KOL_Profile_Portal__pc);
    console.log("formData.selectedKolStatus", formData.selectedKolStatus);

    await Promise.all(
      formData.finalForm.kolnewData.map(async (x) => {
        await this.addNewKOL(
          x,
          engagementId,
          formData.finalForm.subData.Engagement_Type__c,
          newDatetime,
          masterId,
          formData.value,
          formData.finalForm.subData.Notes_Details__c,
        );
      })
    );
    if (formData.finalForm.subData.Attendee_Type__c != "") {
      const strArr = formData.finalForm.subData.Attendee_Type__c;
      console.log("strArr", strArr);
      await Promise.all(
        strArr.map(async (item) => {
          console.log("address--->", item.id);
          await this.createKolAttendee({
            Leadership_Attendee__c: false,
            Master_Engagement__c: engagementId,
            Employee__c: item.id,
            Attendee_Role__c: item.Attendee_Role__c,
            Send_Invite_Email__c: item.email,
            Host_Email__c: formData.finalForm.subData.Host_Email__c,
            Host_Name__c: formData.Host_Name__c,
            Email_Subject_Line__c: formData.Email_Subject_Line__c,
            userId: this.KOL_UserID,
            Attendee_Name__c: item.name,
            Type__c: formData.value,
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          });
        })
      );

    }

    if (formData.finalForm.subData.Attendee_Leadership__c != "") {
      const strArr1 = formData.finalForm.subData.Attendee_Leadership__c
      if (strArr1.length == 1) {
        await this.createKolAttendee({
          Leadership_Attendee__c: true,
          Master_Engagement__c: engagementId,
          Employee__c: formData.finalForm.subData.Attendee_Leadership__c[0].value,
          Send_Invite_Email__c: formData.finalForm.subData.Attendee_Leadership__c[0].email,
          Host_Email__c: formData.finalForm.subData.Host_Email__c,
          Host_Name__c: formData.Host_Name__c,
          Email_Subject_Line__c: formData.Email_Subject_Line__c,
          userId: this.KOL_UserID,
          Attendee_Name__c: formData.finalForm.subData.Attendee_Leadership__c[0].name,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
        });
      } else {
        await Promise.all(
          strArr1.map(async (item) => {
            console.log("address1--->", item);
  
            await this.createKolAttendee({
              Leadership_Attendee__c: true,
              Master_Engagement__c: engagementId,
              Employee__c: item.value,
              Send_Invite_Email__c: item.email,
              Host_Email__c: formData.finalForm.subData.Host_Email__c,
              Host_Name__c: formData.Host_Name__c,
              Email_Subject_Line__c: formData.Email_Subject_Line__c,
              userId: this.KOL_UserID,
              Attendee_Name__c: item.name,
              Type__c: formData.value,
              Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
            });
          })
        );

      }
    }

    await Promise.all(
      formData.finalForm.kmeAryData.map(async (x) => {
        console.log("x---->", x);
        await this.createKolEngagement({
          Master_Engagement__c: engagementId,
          KOLAccount__c: x.KOLAccount__c,
          Attendee_Role__c: x.Attendee_Role__c,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Engagement_Date__c: newDatetime,
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Status__c: x.Status__c,
          Send_Invite_Email__c: x.email,
          Host_Email__c: formData.finalForm.subData.Host_Email__c,
          Host_Name__c: formData.Host_Name__c,
          Email_Subject_Line__c: formData.Email_Subject_Line__c,
          userId: this.KOL_UserID,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
        });
      })
    )
  }

  //below is the othe post maybe delete and move into other sfdc file //


  public async returnFieldInsightsDoc(id) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/returnFieldInsightsDoc/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetch FieldInsightsDoc:", error);
      throw error;
    }
  }
  public async returnTypesByProfile(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/returnTypesByProfile`);
      return response.data;
    } catch (error) {
      console.error("Error fetching returnTypesByProfile:", error);
      throw error;
    } 
  }

  public async updateTheKOLstatusEachNEW(data) {
    const emptyUpdateArray = [] as any;
    data.forEach((x) => {
      let uObj = {
        Id: x.id,
        Master_Engagement__c: x.Master_Engagement__c,
        Status__c: x.Status__c,
      };
      emptyUpdateArray.push(uObj);
    });
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/updateTheKOLstatusEachNEW`);
      return response.data;
    } catch (error) {
      console.error("Error fetching updateTheKOLstatusEachNEW:", error);
      throw error;
    } 
  }

  // BIO GEN KOL CALL
  public async fetchCongressMeetings() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCongressMeetings`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchCongressMeetings:", error);
      throw error;
    } 
  }

  public async fetchCongressMeetingAttendees(Id) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCongressMeetingAttendees?congressId=${process.env.REACT_APP_KOL_CONGRESS_ID ?? ``}&id=${Id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchCongressMeetingAttendees", error);
      throw error;
    }
  }

  // Reports Page
  public async getPersonContacts() {
    try {
      console.log('profilePortal', this.KOL_Profile_Portal__pc)
      const response = await axios.get(`${this.KOL_Api_Url}/getPersonContacts?kolPortal=${this.KOL_Portal__c}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchTwitterUsers:", error);
      throw error;
    }
  }


  public async getKolProfiles() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKolProfiles/${this.KOL_Portal__c}`);
      return response.data;
    } catch (error) {
      console.error("Error getKolProfiles", error);
      throw error;
    }
  }

  public async getMeetingsForPerson(personId: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getMeetingsForPerson?personId=${personId}`);
      return response.data;
    } catch (error) {
      console.error("Error getKolProfiles", error);
      throw error;
    }
  }

  public async getReports(meetingIdList: string[]) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getReports`, {
        params: {
          meetingIdList: meetingIdList
        }
      }
      );
      return response.data;
    } catch (error) {
      console.error("Error getReports", error);
      throw error;
    }
  }

  public async getExpertReports(territory?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getExpertReports/${this.KOL_Profile_Portal__pc}?territory=${territory}`);
      return response.data;
    } catch (error) {
      console.error("Error getExpertReports", error);
      throw error;
    }
  }

  public async getKmeReportsNew(division: string, companycheck: string, FFS__cValue: string) {
    const params = new URLSearchParams();

    if (FFS__cValue !== undefined && FFS__cValue !== "") {
      params.append('ffs', FFS__cValue);
    }
    params.append('division', division);
    params.append('companyCheck', companycheck);
    params.append('profilePortal', this.KOL_Profile_Portal__pc);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKmeReportsNew`, {params: params});
      return response.data;
    } catch (error) {
      console.error("Error getKmeReportsNew2", error);
      throw error;
    }
  }

  public async getKmeReportsNewExcel(division: string, companycheck: string, FFS__cValue: string) {

    const params = new URLSearchParams();

    params.append('division', division);
    params.append('companycheck', companycheck);
    params.append('congressLabel', 'ASCO 2023')

    if (FFS__cValue !== undefined && FFS__cValue !== "") {
      params.append('ffs', FFS__cValue);
    }

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKmeReportsNewExcel`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async getAshReport(division: string, companycheck: string, FFS__cValue: string) {

    const params = new URLSearchParams();

    if (FFS__cValue !== undefined && FFS__cValue !== "") {
      params.append('ffs', FFS__cValue);
    }
    params.append('division', division);
    params.append('Congress_Label__c', 'ASH 2023');
    params.append('companycheck', companycheck);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getAshReport`, {params:params});
      return response.data;
    } catch (error) {
      console.error("Error getAshReport", error);
      throw error;
    }
  }

  public async getKmeReportsPagination(
    instanceUrl: string,
    nextRecordsUrl: string,
    authToken: string
  ) {
    //Leadership_Attendee__c

    fetch(
      "https://janssensupport.360connex.com/services/data/v42.0/query/01g3Z0000EofJWEQQ2-238",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer 00Dj0000001ujh3!ARoAQG4tivynAQA.1STgy40D1_3QjERbAwsK9XxzzU0Uos0.eVODbwl3damFRxXPh42MP1LkXPJPFsDxA3h.S0nK7c5YVth5",
        },
        method: "GET",
      }
    );
  }

  public async getKmeReports() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKmeReports/${this.KOL_Profile_Portal__pc}/${'HCP'}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKmeReports:", error);
      throw error;
    }
  }

  public async getSpeakerReports() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getSpeakerReports/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getSpeakerReports:", error);
      throw error;
    }
  }

  public async getKOLReports(queryParams: string, FFS__cValue: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLReports`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLReports:", error);
      throw error;
    } 
  }

  public async getKOLReports2(queryParams: string, FFS__cValue: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLReports2?KOL_Portal_Name=${this.KOL_Portal_Name}&brandsFilter=${BRAND2}&ffSc=${FFS__cValue}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLReports2:", error);
      throw error;
    }
  }
  // <=========================MM portfolio======================================>
  public async getTotalKOlEngagement(queryParams: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getTotalKOlEngagement?KOL_Portal_Name=${this.KOL_Portal_Name}&brand=${BRAND2}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getTotalKOlEngagement:", error);
      throw error;
    }
  }


  public async getTargetKOl(queryParams: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getTargetKOl?KOL_Portal_Name=${this.KOL_Portal_Name}&brand=${BRAND2}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getTargetKOl:", error);
      throw error;
    }
  }

  public async getLeadershipEngagement() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getLeadershipEngagement?KOL_Portal_Name=${this.KOL_Portal_Name}&brand=${BRAND2}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchTwitterUsers:", error);
      throw error;
    }
  }


  public async getSummaryLevelNontargetKol(queryParams: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getSummaryLevelNontargetKol/${this.KOL_Portal_Name}/${BRAND2}`);
      return response.data;
    } catch (error) {
      console.error("Error getSummaryLevelNontargetKol:", error);
      throw error;
    }
  }
// <==========================================leukemia reports api=============================>

public async getKOLReportsDemo(FFS__cValue: any) {
  try {
    const params = new URLSearchParams();

    if (FFS__cValue !== undefined && FFS__cValue !== "") {
      params.append('ffs', FFS__cValue);
    }
    params.append('KOL_Portal_Name', this.KOL_Portal_Name);
    params.append('brandName', BRANDdemo);

    const response = await axios.get(`${this.KOL_Api_Url}/getKOLReportsDemo`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching getKOLReportsDemo:", error);
    throw error;
  }
}


public async getKOLReports3(queryParams: string, FFS__cValue: string) {
  console.log('from getKOLReports3');

  try {
    const params = new URLSearchParams();

    if (FFS__cValue !== undefined && FFS__cValue !== "") {
      params.append('ffs', FFS__cValue);
    }

    params.append('BrandMasterName', BRAND3)
    params.append('KOL_Portal_Name', this.KOL_Portal_Name);
    const response = await axios.get(`${this.KOL_Api_Url}/getKOLReports3`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching getKOLReportsDemo:", error);
    throw error;
  }
}

  public async getKmeWriteinReports(queryParams1: any, FFS__cValue: string) {
    const body = {
      focusNames: queryParams1,
      KOL_Profile_Portal__Name: this.KOL_Portal_Name,
      pageSize: 100,
      pageNumber: 1
    }
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getKmeWriteinReports`,
        body
      );
      return response.data;
    } catch (error) {
      console.error("Error getKmeWriteinReports: ", error);
      throw error;
    }
  }

  public async fetchTwitterUsers() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTwitterUsers`);
      return response.data;
    } catch (error) {
      console.error("Error fetchTwitterUsers:", error);
      throw error;
    }
  }

  public async fetchEventsForMostLeast(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEventsForMostLeast`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchEventsForMostLeast:", error);
      throw error;
    } 
  }

  public async fetchKolCheckboxIMBRUVICA(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKolCheckboxIMBRUVICA/${profileId}?brand=${BRAND1}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchKolCheckboxIMBRUVICA:", error);
      throw error;
    }
  }

  public async fetchKolCheckboxDARZALEX(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKolCheckboxDARZALEX/${profileId}?brand=${BRAND2}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchKolCheckboxDARZALEX:", error);
      throw error;
    }
  }

  public async fetchKolCheckboxERLEADA(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKolCheckboxERLEADA?profileId=${profileId}&brand=${BRAND3}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchInstitutionNotes", error);
      throw error;
    }
  }

  public async fetchKolCheckbox(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKolCheckbox/${profileId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchKolCheckbox", error);
      throw error;
    }
  }

  public async fetchProfileBrandName(
    profileId?: string,
    companycheck?: string
  ) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchProfileBrandName/${companycheck}/${profileId}/${BRAND1}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchProfileBrandName", error);
      throw error;
    }
  }

  public async getProfileBrandData(profileId?: string, companycheck?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getProfileBrandData?profileId=${profileId}&brand=${BRAND1}&companycheck=${companycheck}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getProfileBrandData: ", error);
      throw error;
    }
  }

  public async getContactDetail(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getContactDetail/${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error getContactDetail", error);
      throw error;
    }
  }

  public async fetchSpeakerActivity(profileId?: string, FFS__cValue?: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchSpeakerActivity/${profileId}/${FFS__cValue}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchSpeakerActivity", error);
      throw error;
    }
  }

  public async fetchInstitutionNotes(profileId?: string, Division__c: string = '') {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchInstitutionNotes/${profileId}?division=${Division__c}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchInstitutionNotes", error);
      throw error;
    }
  }

  public async fetchCareNotes(profileId?: string, Division__c: string = '') {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCareNotes/${profileId}?division=${Division__c}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchCareNotes:", error);
      throw error;
    }
  }


  public async fetchAll(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchAll/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching All:", error);
      throw error;
    }
  }

  public async getPermission(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getPermission?profileId=${profileId}&profilePortal=${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching permissions:", error);
      throw error;
    }
  }

  public async getClassification(profileId?: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getClassification/${this.KOL_Profile_Portal__pc}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getClassification", error);
      throw error;
    }
  }


  public async getGlobal(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getGlobal/${this.KOL_Profile_Portal__pc}/employee/${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global:", error);
      throw error;
    }
  }


  public async getGlobalName(profileId?: string): Promise<string> {
    try {

      const response = await axios.get(`${this.KOL_Api_Url}/getGlobalName?profileId=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global name:", error);
      throw error;
    }
  };

  public async getGlobalDivision(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getGlobalDivision/${profileId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getGlobalDivision", error);
      throw error;
    }
  }


  public async getGlobalFocus(profileId?: string): Promise<any> {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getGlobalFocus/${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  };

  public async getGlobalFocus2(profileId?: string) {
    let profileName = this.KOL_Portal_Name;
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/GetGlobalFocus2/${profileName}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async getMasterId(brandName?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Base_Url}/brandmasters/${brandName}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching brand master:", error);
      throw error;
    }
  }

  public async getFocusId(
    focusId?: string,
    focusName?: string,
    focus?: string
  ) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getFocusId/${focusName}/${focus}/${focusId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async getTopicId(topicId?: string, topicName?: string) {
    const data = {
      topicId: topicId,
      topicName: topicName
    }
    try {
      const response = await axios.post(`${this.KOL_Api_Url}/getTopicId`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching getTopicId:", error);
      throw error;
    }
  }
  
  public async getPowerBIToken() {
    try {
      const response = await axios.get(`${this.KOL_Api_Base_Url}/powerbi-token`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getPowerBIToken:", error);
      throw error;
    }
  };

  public async testingapi(profileId?: string) {
  }
}

export { SfdcApi };
