import React, { Fragment } from "react";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link, useHistory } from "react-router-dom";
import { useSfdc } from "../../lib/useSfdc";
import {
  makeStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import SupportModal from "../../components/modal/support/SupportModal";
import { NAVIGATION } from "../utilities/const";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    sideItems: {
      borderBottom: "1px lightgray solid",
    },
    nested: {
      paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
      // backgroundColor: "rgb(243,249,248)",
    },
    itemText: {
      fontSize: 14,
      fontWeight: "bolder",
    },
    subItemText: {
      fontSize: 14,
    },
  })
);

interface ISubNav {
  path: string;
  label: string;
}

interface INav {
  path: string;
  label: string;
  subNav?: ISubNav[];
}

interface INavProps {
  nav: INav;
}


const NavItem = (props) => {

  const { nav }: INavProps = props;
  const classes = useStyles();
  const history = useHistory();


  const [open, setOpen] = React.useState(false);

  const handleClick = (nav: INav) => {
    if (nav?.subNav) {
      setOpen(!open);
    } else {
      history.push(nav.path);
      props.handleNavigationMenu();
    }

  };

  

  return (
    <>
      <ListItem className={classes.sideItems} button
        onClick={() => handleClick(nav)}
      >
        <ListItemText classes={{ primary: classes.itemText }}
          className="f-color-g" primary={nav.label}
        />
        {nav.subNav && open && <ExpandLess />}
        {nav.subNav && !open && <ExpandMore />}
      </ListItem>

      {nav.subNav &&
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {nav.subNav.map((subNav, subNavIndex: number) => (
              <Fragment key={subNavIndex}>
                <ListItem className={classes.nested} button
                  component={Link}
                  to={subNav.path}
                  onClick={() => handleClick(subNav)}
                >
                  <ListItemText classes={{ primary: classes.subItemText }}
                    primary={subNav.label}
                  />
                </ListItem>
              </Fragment>
            ))
            }
          </List>
        </Collapse>
      }
    </>
  );
}

const NavigationMenu = (props) => {
  const handleNavigationMenu = () => {
    props.handleNavigationMenu(false)
  }

  const {
    user,
    loginStatus,
  } = useSfdc();


  const closeNavBar=()=>{
    props.handleNavigationMenu(false)
  }


  return (
    <div id="menu_outer">
     
	 
	 
	 <div className="menu_name"><Link to='/' onClick={closeNavBar}>Dashboard</Link></div>
	  <div className="menu_list boder_line">
	   <div className="menu_name black_link"><span>Scheduling / Information</span></div>
	   <div className="menu_name sub_link"><Link to='/kol-profiles' onClick={closeNavBar}>KOL Search</Link></div>
	   <div className="menu_name sub_link"><Link to='/calendar' onClick={closeNavBar}>Calendar</Link></div>
	   <div className="menu_name sub_link"><Link to='/institutions' onClick={closeNavBar}>Institutions</Link></div>
	   <div className="menu_name sub_link"><Link to='/kol-reports' onClick={closeNavBar}>Reports</Link></div>
	  </div> 
	  
	  
	    <div className="menu_list boder_line border-top-none">
	    <div className="menu_name black_link"><span>Resources / Social / Other</span></div>
	  <div className="menu_name sub_link"><Link to='/kol-network-map'onClick={closeNavBar}>Leaders Network Map</Link></div>
	   <div className="menu_name sub_link"><Link to='/social-media' onClick={closeNavBar}>Social Media</Link></div>
	   <div className="menu_name sub_link"><Link to='/resources' onClick={closeNavBar}>Resources</Link></div>
	   
	  </div> 
	 
	 
      <SupportModal onNavBar={closeNavBar}/>

      {user ? (
            <div className="menu_name log_out"><span onClick={() => {
                  loginStatus(false);
                }}>Log Out</span></div>
          ) : (
            ''
          )}
	  
	    
		
    </div>
  );
};

export default NavigationMenu;
