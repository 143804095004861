import { AppEvents } from './events';

export interface IAction {
    type: AppEvents;
    payload: any;
}

const makeAction = <T extends AppEvents, P>(type: T) => (payload: P) => {
    return {
        type,
        payload
    }
}

export const SetName = makeAction<AppEvents.SET_NAME, string>(
    AppEvents.SET_NAME
);

export const SetGattexAttendees = (payload: string) => {
    return {
        type: AppEvents.SET_GATTEX_ATTENDEES,
        payload
    }
}

export const SetPayloadStatus = (payload: string) => {
    return {
        type: AppEvents.SET_PAYLOAD_STATUS,
        payload
    }
}

export const SetFormData = (payload: object) => {
    return {
        type: AppEvents.SET_FORM_DATA,
        payload
    }
}


export const SetAge = (payload: number) => {
    return {
        type: AppEvents.SET_AGE,
        payload
    }
}

export const SetMultipleAttendees = (payload: any) => {
    return {
        type: AppEvents.SET_MULTIPLE_ATTENDEES,
        payload
    }
}

export const AddNewKolStatus = (payload: any) => {
    return {
        type: AppEvents.ADD_NEW_STATUS,
        payload
    }
}

export const ClearNewKolStatus = (payload: any) => {
    return {
        type: AppEvents.CLEAR_NEW_STATUS,
        payload
    }
}

export const SetNewTags = (payload: any) => {
    return {
        type: AppEvents.SET_TAGS,
        payload
    }
}

export const FetchKolDropDown = (payload: any) => {
    return {
        type: AppEvents.FETCH_KOLS_DD,
        payload
    }
}

export const FetchGkDropDown = (payload: any) => {
    return {
        type: AppEvents.FETCH_GATTEX_DD,
        payload
    }
}

export const SetLoadingStatus = (payload: any) => {
    return {
        type: AppEvents.SET_LOADING_STATUS_NE,
        payload
    }
}

export const SetYearsList = (payload: any) => {
    return {
        type: AppEvents.SET_YEARS_LIST,
        payload
    }
}

export const SetBrandFilters = (payload: any) => {
    return {
        type: AppEvents.SET_BRAND_FILTERS,
        payload
    }
}
export const SetBrandFiltersNew = (payload: any) => {
    return {
        type: AppEvents.SET_BRAND_FILTERS_NEW,
        payload
    }
}
export const SetBrandFocus = (payload: any) => {
    return {
        type: AppEvents.SET_BRAND_FOCUS,
        payload
    }
}
export const SetBrandFocusAll = (payload: any) => {
    return {
        type: AppEvents.SET_BRAND_FOCUS_ALL,
        payload
    }
}
export const SetBrandColors = (payload: any) => {
    return {
        type: AppEvents.SET_BRAND_COLORS,
        payload
    }
}


export const SetMyView = (payload: any) => {
    return {
        type: AppEvents.SET_MYVIEW_FILTERS,
        payload
    }
}

export const SetHeaderValue = (payload: any) => {
    return {
        type: AppEvents.SET_HEADER_VALUE,
        payload
    }
}

export const SetremovedItems = (payload: any) => {
    return {
        type: AppEvents.SET_REMOVED_VALUE,
        payload
    }
}


export const SetCongressValue = (payload: any) => {
    return {
        type: AppEvents.SET_CONGRESS_VALUE,
        payload
    }
}

export const SetYearValue = (payload: any) => {
    return {
        type: AppEvents.SET_YEAR_VALUE,
        payload
    }
}


export const SetTypeValue = (payload: any) => {
    return {
        type: AppEvents.SET_TYPE_VALUE,
        payload
    }
}


export const SetCongressFilter = (payload: any) => {
    return {
        type: AppEvents.SET_CONGRESS_FILTER,
        payload
    }
}

export const SetInsitutionValue = (payload: any) => {
    return {
        type: AppEvents.SET_INSITUTION_VALUE,
        payload
    }
}

export const SetModal = (payload: any) => {
    return {
        type: AppEvents.SET_MODAL,
        payload
    }

}
export const SetUrlData = (payload: any) => {
    return {
        type: AppEvents.SET_URL_DATA,
        payload
    }
}
export const SetrefreshStatus = (payload: any) => {
    return {
        type: AppEvents.SET_REFRESH_STATUS,
        payload
    }

}
export const SetsetPrint = (payload: any) => {
    return {
        type: AppEvents.SET_SET_PRINT,
        payload
}
}

interface IStringMap<T> {
    [key: string]: T
}
type IAnyFunction = (...args: any[]) => any;
type IActionUnion<A extends IStringMap<IAnyFunction>> = ReturnType<A[keyof A]>

// const actions = {
//     SetName,
//     SetAge,
//     SetGattexAttendees,
//     SetPayloadStatus,
//     SetFormData,
//     SetMultipleAttendees,
//     AddNewKolStatus,
//     SetNewTags,
//     FetchKolDropDown,
//     FetchGkDropDown,
//     SetLoadingStatus
// };

// export type IAction = IActionUnion<typeof actions>;