import React, { Fragment, useEffect, useState , useMemo} from "react";
import logo from '../../imgs/360Connexglobal.png';
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import { useSfdc } from "../../lib/useSfdc";
import { Image } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { CustomDialog } from "../components/CustomDialog";
import { CustomAlert } from "../components/CustomAlert";
import moment from "moment";
import {
  SetBrandColors,
  SetHeaderValue,
  SetremovedItems,
  SetCongressValue,
  SetYearValue,
  SetTypeValue,
  SetBrandFiltersNew,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { AppEvents } from "../../events";
import MultiselectCheckbox from "../components/MultiselectCheckbox";
import CloseIcon from "@material-ui/icons/Close";


const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
    height: 110,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hamburgerHidden: {
    display: "none",
  },
  button: {
    margin: theme.spacing(2),
    border: "#fff solid 1px",
    padding: "1px 7px",
    fontSize: "13px",
  },
  title: {
    flexGrow: 1,
  },
  header_clear_all_2 : {
    cursor:'pointer',
  },
  hamburger: {
    border: "2px solid #FFF",
    padding: theme.spacing(1),
    marginLeft: 0,
    marginRight: theme.spacing(2),
  },
  menu: {
    flexGrow: 1,
  },
  logo: {
    maxHeight: 42,
  },

}));

interface IInitModal {
  Default_Classes__c: string;
  KOL_Profile_Portal__c: string;
  Message_Type__c: string;
  Message__c: string;
  Sort_Order__c: string;
  Valid_Date__c: string;
}

const LogoBar = ({ open, handleDrawerOpen, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    user,
    loginStatus,
    kolProfile,
    firstTimeLogin,
    setFirstTimeLogin,
    api,
    UserOrGroupId,
    division,
    globalData,
    globalFocus,
  } = useSfdc();
  const history = useHistory();
  const [modal, setModal] = useState<boolean>(firstTimeLogin || false);
  const [modalContent, setModalContent] = useState<IInitModal>(
    {} as IInitModal
  );
  const [apiStatus, setApiStatus] = React.useState<number>(0); // 0 Not triggered, 1 loading, 2 Success,  3 Error
  const [checkedBrand, setCheckedBrand] = React.useState<any>([]);
  //const [removedItems,setremovedItems] = React.useState<any>([]);
  const [ischeckbox, setIsCheckbox] = useState<boolean>(false);
  
  

  const {
    headerValue,
    brandFocus,
    brandFocusAll,
    brandFilters,
    removedItems
  } = useSelector((state: any) => state.app);
  // console.log("HEADERVALUEDUPLICATE",headerValue)


  let multiSelectCheckbox = useMemo(()=>(<MultiselectCheckbox removedItems={removedItems} />),[removedItems]);

  useEffect(() => {

    var queryParams1 = "";

    brandFilters.map((v, i) => {
      if (i == 0) {
        queryParams1 = `Brand_Master__r.Name = '${v}' `
      } else {
        queryParams1 = queryParams1 + ` or Brand_Master__r.Name = '${v}' `
      }
    })
  
    var queryParams = "";
  
    //console.log("brandFocus>>",brandFocus)
  
    brandFocus.map((v, i) => {
      if (i == 0) {
        queryParams = `Master_Engagement__r.Focus_Master__r.Name = '${v.value}' `;
      } else {
        queryParams = queryParams + ` or Master_Engagement__r.Focus_Master__r.Name = '${v.value}' `;
      }
    });


    if (globalData?.[0]) {
     // console.log("globalData logo--->", globalData);

      let brand_check = globalData?.[0]?.KOL_Employee_Brands__r?.records.map((value) => ({
        [value.Brand_Master__r.Name]: value.Brand_Master__r.Color__c,
      }));
     // console.log(brand_check);

      let finalObj = {};

      // loop elements of the array
      if (brand_check.length > 0) {
        for (let i = 0; i < brand_check.length; i++) {
          Object.assign(finalObj, brand_check[i]);
        }
      }

      let sorted = Object.keys(finalObj)
        .sort()
        .reduce(function (acc, key) {
          acc[key] = finalObj[key];
          return acc;
        }, {});



      /////////////////////////////////////////////////////////////////


      //"globalFocus",globalFocus)
      let focusArray:any=[];
      let headerArray:any=[];
  
      globalFocus?.forEach(x=>focusArray.push(x.Name) && headerArray.push(x.Name)&& 
      x?.KOL_Focus_Masters__r?.records?.forEach(y=>focusArray.push(y.Name+"*"+x.Name)));
      //console.log("focusArray1",focusArray);
     
    
      //console.log("focusArray2",focusArray)
      const focusArray1=[];
      const headerArray1=[];
  
      focusArray1["records"] = focusArray;
      headerArray1["records"] = headerArray;
  

      //dispatch(SetHeaderValue(newArray1));
      dispatch(SetHeaderValue(headerArray1));
      dispatch(SetBrandFiltersNew(focusArray1));

      

      dispatch(SetBrandColors(sorted));
      dispatch(SetTypeValue(globalData?.[0]?.Division__c));
    }

    setApiStatus(1);
   if(queryParams1!=''){Promise.all([api?.fetchProfilePortalMessage(), api?.fetchCongress()]).then(
      (data: any) => {
        if (data) {
          setModalContent(data[0]?.records?.[0]);
          if (data[1] != undefined) {

            data[1].records.sort(function(a, b) {
                return a.Label__c.localeCompare(b.Label__c)
            });

            dispatch(SetCongressValue(data[1]));
            //console.log("Cnogress---> ", data[1]);
            // if(data[1]?.records)
            // {
            //   let filterOption = data[1].records.map((item) => item.Name.split(" ")[1]);
            //   dispatch(SetBrandFilters(filterOption));
            // }
          }
        }
        setApiStatus(2);
      },
      (error) => {
        setApiStatus(3);
      }
    );
    }
  }, [api, division, globalData,globalFocus,brandFilters.length,brandFocus.length]);

  const checkDate = (date: string) => {
    return moment() < moment(moment(date).format("YYYY-MM-DD"));
  };

  


  useEffect(() => {
    setCheckedBrand((prev) => {
      const prevData = [...prev];
      headerValue?.records?.map((item) => prevData?.push(item));
      return prevData;
    });
  }, [headerValue?.records?.length]);
  useEffect(() => {
    if (
      firstTimeLogin &&
      modalContent?.Valid_Date__c &&
      checkDate(modalContent.Valid_Date__c)
    ) {
      setModal(true);
      setFirstTimeLogin(false);
    }
  }, [firstTimeLogin, modalContent]);

  const initDialog = () => {
    return (
      <CustomDialog
        dialogState={modal}
        handleClose={() => setModal(false)}
        title={modalContent?.Message_Type__c}
        maxWidth={"sm"}
      >
        {apiStatus === 2 && modalContent?.Message_Type__c ? (
          <CustomAlert severity={"info"} message={modalContent?.Message__c} />
        ) : (
          <CustomAlert
            apiStatus={
              apiStatus === 2 && !modalContent?.Message_Type__c ? 0 : apiStatus
            }
          />
        )}
      </CustomDialog>
    );
  };

  const handleNavigation = () => {
    history.push("/");
  };

  const closeFilter = (item: any) => {

//console.log("closeFilter",item)


var removeByAttr = function(arr, attr, value){
  var i = arr.length;
  while(i--){
     if( arr[i] 
         && arr[i].hasOwnProperty(attr) 
         && (arguments.length > 2 && arr[i][attr] === value ) ){ 

         arr.splice(i,1);

     }
  }
  return arr;
}




const index = brandFocusAll.findIndex(object => {
  return object.value === item;
});


//const index = brandFocusAll?.indexOf(item);
if(index > -1){
  brandFocusAll?.splice(index,1);
//   console.log("brandFocusFromlogobar",brandFocusAll)
  
//   dispatch(SetremovedItems(brandFocusAll));
  

 }
 let e2=brandFocusAll;
headerValue.records.forEach(y=>{
  if(!(e2.find(z=>z.value==y))){
    //console.log("true>>>")
    removeByAttr(e2, 'name', y)
    
  }
  
})

//console.log("removedItems",removedItems)


dispatch(SetremovedItems(e2));



  };



    const clearAllFiltershandler=()=>{
   // console.log("brandFocusAll>>>",brandFocusAll)
   const length=brandFocusAll?.length;
   if(length>0){
    brandFocusAll?.splice(0,length)
    dispatch(SetremovedItems(brandFocusAll))
   }
   // By deafult Brands
  // let brandobject:any = []
  // brandFilters.forEach(x=>{
  //   brandobject.push({value: x, label: x})
  // })

  // dispatch(SetremovedItems(brandobject))
  }





  const [formSelect, setFormSelect] = React.useState();


  useEffect(() => {
    dispatch({ type: AppEvents.SET_FORM_SELECT, payload: formSelect });
    const currentYear = new Date().getFullYear();
    dispatch(SetYearValue(currentYear));
  }, [formSelect]);

  // console.log(
  //   "checked Brand List =================================> ",
  //   checkedBrand
  // );
  return (
    <Fragment>
      {/* {initDialog()} */}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, open && classes.appBarShift)}
        id="header_outer"
        style={{ zIndex: 10 }}
      >
        <Toolbar className={classes.toolbar}>
          <Box className={classes.menu}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={user && handleDrawerOpen}
              // className={classes.hamburger}
              className={clsx(
                classes.hamburger,
                open && classes.hamburgerHidden
              )}
              id="burger_menu"
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              id="logo_outer"
              edge="start"
              color="inherit"
              aria-label="logo"
              onClick={user && handleNavigation}
            >
              {kolProfile?.Logo__c ? (
                <img
                  alt="logo 123"
                  src={logo}
                  className={classes.logo}
                />
              ) : (
                
                <img
                  alt="logo 123"
                  src={logo}
                  className={classes.logo}
                />
              )}
            </IconButton>
          </Box>
        {globalData?.length>0? multiSelectCheckbox :<></>}
        
 
          {user ? (
            ""
          ) : (
       

            ""
            // <Button
            //   id="log_in_out"
            //   color="inherit"
            //   className={classes.button}
            //   onClick={() => {
            //     loginStatus(true);
            //     setIsCheckbox(true)
            //   }}
            // >
            //   {" "}
            //   Login{" "}
            // </Button>
          )}
        </Toolbar>
        <section className="sub_header">
      <div className="brandfocusfilters">
       {globalFocus?.length>0? <div className="applied_filter"> Applied Filters:</div>:<></>}
        <div className="applied_tags">
          {brandFocusAll && brandFocusAll?.map((itm, i) => {
                  if (itm) {
                    return (
                      <div className="filtername" key={itm.value}>
                        {itm.value}
                        <IconButton
                          className="closeicon"
                          color="inherit"
                          aria-label="close"
                          onClick={() => {
                            closeFilter(itm.value);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    );
                  }
                })}
                </div></div>
       { brandFocusAll?.length > 0 ?
        <div className={classes.header_clear_all_2} onClick={clearAllFiltershandler}>
          Clear Filtering
        </div>
        : <></>
       }
      </section>
      </AppBar>
     
    </Fragment>
  );
};

export default LogoBar;
